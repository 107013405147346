import { useSelector } from "react-redux";

import { useLastEcardActions } from "store/LastEcardSlice";
import { ECARD_TYPES } from "enums";
import useUsers from "hooks/useUsers";
import localStorage, { ECARD, TEMPLATE_GROUP, IN_FLOW, SOURCE_PHOTO, MORPHABLE_PACK_GROUP } from "utils/localStorage";
import UserPresenter from "presenters/UserPresenter";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";

const useLastEcard = () => {
  const { currentUser } = useUsers();
  const { setLastEcard, setInFlow, clearLastEcard, setSourcePhoto } = useLastEcardActions();

  const {
    ecard: currentEcard,
    templateGroup: currentTemplateGroup,
    inFlow: currentFlow,
    sourcePhoto: currentPhoto,
    morphablePackGroup: currentMorphablePackGroup,
  } = useSelector((state) => state.LastEcardSlice);

  const ecard = (() => currentEcard || localStorage.getItem(ECARD))();
  const templateGroup = (() => currentTemplateGroup || localStorage.getItem(TEMPLATE_GROUP))();
  const inFlow = (() => currentFlow || localStorage.getItem(IN_FLOW))();
  const sourcePhoto = (() => currentPhoto || localStorage.getItem(SOURCE_PHOTO))();
  const morphablePackGroup = (() => currentMorphablePackGroup || localStorage.getItem(MORPHABLE_PACK_GROUP))();

  const isInvite = ecard && ecard.type === ECARD_TYPES.INVITE;
  const isMake = ecard && ecard.type === ECARD_TYPES.MAKE;
  const isMorphable = ecard && ecard.type === ECARD_TYPES.MORPHABLE;

  const isFlowRequired =
    UserPresenter.notRegistered(currentUser) ||
    (UserPresenter.notPaid(currentUser) && TemplateGroupPresenter.premium(templateGroup));

  return {
    setLastEcard,
    setSourcePhoto,
    setInFlow,
    inFlow,
    clearLastEcard,
    ecard,
    isInvite,
    isMake,
    isMorphable,
    templateGroup,
    isFlowRequired,
    sourcePhoto,
    morphablePackGroup,
  };
};

export default useLastEcard;
