import { useSelector } from "react-redux";

import useSYAIErrorHandler from "hooks/useSYAIErrorHandler";
import useUsers from "hooks/useUsers";
import useAssetUpload from "hooks/useAssetUpload";
import AWSRepository from "repositories/AWSRepository";
import { useSourcePhotoActions } from "store/SourcePhotoSlice";
import { jjLogger } from "utils/logUtils";

const useSourcePhoto = () => {
  const { createSourcePhoto, openModal, closeModal } = useSourcePhotoActions();
  const { sourcePhoto, modalOpened } = useSelector((state) => state.SourcePhotoSlice);
  const { ensureCurrentUser } = useUsers();
  const { uploadSourcePhotoAsset } = useAssetUpload();
  const { handlePhotoError } = useSYAIErrorHandler();

  const buildAttributes = (key) => ({
    data: {
      type: "source-photos",
      attributes: {
        asset: {
          "s3-key": key,
        },
      },
    },
  });

  const saveSourcePhoto = async (imageBlob, groupSlug) => {
    try {
      await ensureCurrentUser();
      const presignedRequest = AWSRepository.presignedUploadToS3withToken(
        { method: "faceswap_source_photo" },
        imageBlob,
      );
      const key = await uploadSourcePhotoAsset(presignedRequest);
      return await createSourcePhoto(buildAttributes(key));
    } catch (error) {
      jjLogger.logError(`useSourcePhoto.js: saveSourcePhoto() Error: ${error}`);
      handlePhotoError(groupSlug);
      throw error;
    }
  };

  return {
    saveSourcePhoto,
    sourcePhoto,
    openModal,
    closeModal: () => closeModal(),
    modalOpened,
  };
};

export default useSourcePhoto;
