/* global DetectUserAgent */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isNil } from "ramda";

import { useFacebookActions } from "store/FacebookSlice";
import {
  init,
  login,
  getAllAlbums,
  getAllUserInfo,
  getImagesOfAlbum,
  checkLoggedin,
  getNextPage,
  validatePermissions,
  customLogin,
} from "utils/facebookUtils";
import { jjLogger } from "utils/logUtils";

import useSettings from "./useSettings";
import useScreen from "./useScreen";

const FACEBOOK_TOKEN_KEY = "fbToken";

const useFacebook = (initialize = false, isOauth = false) => {
  const {
    loadAlbums,
    loadUserInfo,
    loadPhotos,
    loadAdditionalPhotos,
    loadAdditionalAlbums,
    changeMode,
    changeModalOpen,
    changeSelectedPhoto,
    changeInitialized,
    changeStatus,
    clearPhotos,
    clearSelectedPhoto,
    resetMode,
    clearAlbums,
    clearStatus,
    changeLoading,
    changeNoticeModal,
  } = useFacebookActions();
  const [nextPage, setNextPage] = useState(null);
  const {
    settings: { fbAppId },
  } = useSettings();
  const isInAppBrowser = DetectUserAgent.isInAppBrowser();
  const { isDesktop } = useScreen();

  const { albums, mode, photos, modalOpen, selectedPhoto, initialized, status, showNoticeModal } = useSelector(
    (state) => state.FacebookSlice,
  );
  const loading = useSelector((state) => (isOauth ? true : state.FacebookSlice.loading));

  useEffect(() => {
    if (initialize && !initialized) {
      init(fbAppId, changeInitialized).catch((error) => {
        changeLoading(false);
        jjLogger.logError(`useFacebook.js: init(): error initializing facebook sdk - error: ${JSON.stringify(error)}`);
      });
    }
  }, []);

  const storeFacebookToken = (token) => {
    sessionStorage.setItem(FACEBOOK_TOKEN_KEY, token);
  };

  const getFacebookToken = () => sessionStorage.getItem(FACEBOOK_TOKEN_KEY);

  const changeModalOpenStorage = (value) => {
    if (isInAppBrowser) {
      sessionStorage.setItem("facebookModal", value);
    }
    changeModalOpen(value);
  };

  const availableAlbums = albums?.filter((album) => !isNil(album?.cover_photo?.images));

  const hasSelectedPhoto = !isNil(selectedPhoto) && !isNil(selectedPhoto?.source);

  const checkLoggedinStatus = () => {
    return checkLoggedin()
      .then((currentStatus) => validatePermissions(currentStatus, getFacebookToken()))
      .then((res) => {
        changeStatus(res);
        changeLoading(false);
      })
      .catch((error) => {
        changeLoading(false);
        if (!error || error.message !== "Facebook user does not seem connected") {
          jjLogger.logError(
            `useFacebook - checkLoggedinStatus() - error getting login status of user: ${JSON.stringify(error)}`,
          );
        }
      });
  };

  const changeNoticeModalOpen = (value) => {
    if (isDesktop) {
      changeNoticeModal(value);
    }
  };

  const getUserInfo = (accessToken) => {
    return getAllUserInfo(accessToken)
      .then((response) => {
        loadUserInfo(response);
        return response;
      })
      .catch((error) => {
        jjLogger.logError(`useFacebook.js: getUserInfo(): error getting user info: ${JSON.stringify(error)}`);
      });
  };

  const loginFacebook = () => {
    changeLoading(true);
    if (isInAppBrowser) {
      return customLogin(fbAppId);
    }
    changeNoticeModalOpen(true);
    return login()
      .then((response) => {
        changeNoticeModalOpen(false);
        storeFacebookToken(response.authResponse.accessToken);
        changeStatus(response.status);

        return response.authResponse;
      })
      .catch((error) => {
        changeNoticeModalOpen(false);
        changeLoading(false);
        jjLogger.logError(
          `useFacebook.js: loginFacebook(): error when logging in with facebook: ${JSON.stringify(error)}`,
        );
      });
  };

  const getAlbums = () => {
    return getAllAlbums(setNextPage, getFacebookToken())
      .then(loadAlbums)
      .catch(() => {
        jjLogger.logError("useFacebook.js: getAlbums(): error getting user albums");
      });
  };

  const getImages = (albumId) => {
    return getImagesOfAlbum(albumId, setNextPage, getFacebookToken())
      .then(loadPhotos)
      .catch(() => {
        jjLogger.logError("useFacebook.js: getImages(): error getting photos of album");
      });
  };

  const loadNextPage = (onLoaded) => {
    if (!isNil(nextPage)) {
      return getNextPage(nextPage, setNextPage)
        .then(onLoaded)
        .catch(() => {
          jjLogger.logError("useFacebook.js: loadNextPage(): error getting next page of albums or image");
        });
    }
    return null;
  };

  return {
    albums,
    mode,
    photos,
    initialized,
    changeMode,
    changeModalOpenStorage,
    modalOpen,
    availableAlbums,
    selectedPhoto,
    loading,
    changeSelectedPhoto,
    hasSelectedPhoto,
    status,
    loginFacebook,
    loadNextPage,
    loadAdditionalPhotos,
    loadAdditionalAlbums,
    getAlbums,
    getUserInfo,
    getImages,
    getFacebookToken,
    checkLoggedinStatus,
    resetMode,
    clearPhotos,
    clearSelectedPhoto,
    clearAlbums,
    clearStatus,
    storeFacebookToken,
    changeNoticeModalOpen,
    showNoticeModal,
  };
};

export default useFacebook;
