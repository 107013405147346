import { useContext } from "react";

import { FaceOrgContext } from "contexts/faceorg-context";

const useFaceOrg = () => {
  const { entryPoint, setEntryPoint } = useContext(FaceOrgContext);

  const clearEntryPoint = () => {
    setEntryPoint("");
  };

  const trackFaceOrg = (user, relation, hasMoreFaces = false) => {
    if (!hasMoreFaces) clearEntryPoint();
  };

  return { entryPoint, setEntryPoint, clearEntryPoint, trackFaceOrg };
};

export default useFaceOrg;
