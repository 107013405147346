import { useFeatureValue, useFeatureIsOn } from "@growthbook/growthbook-react";

import { FEATURE_FLAGS, OFFER_VARIANTS } from "enums/growthbook";

const useGrowthbookFlags = () => {
  const displayAppleAuth = useFeatureIsOn(FEATURE_FLAGS.DISPLAY_APPLE_BUTTON);

  const sideBySideVsTotalPerMonthExperiment = useFeatureValue(
    FEATURE_FLAGS.SIDE_BY_SIDE_VS_TOTAL_PER_MONTH,
    OFFER_VARIANTS.BASELINE,
  );

  const isBaselineOffer = sideBySideVsTotalPerMonthExperiment === OFFER_VARIANTS.BASELINE;
  const isPerMonthPrice = sideBySideVsTotalPerMonthExperiment === OFFER_VARIANTS.PER_MONTH_PRICE;
  const isFullPrice = sideBySideVsTotalPerMonthExperiment === OFFER_VARIANTS.FULL_PRICE;

  return {
    displayAppleAuth,
    isBaselineOffer,
    isPerMonthPrice,
    isFullPrice,
  };
};

export default useGrowthbookFlags;
