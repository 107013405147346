import { SKIN_COLORS } from "./syai";

const SHARE_METHODS = Object.freeze({
  LINK: "Link",
  EMAIL: "Email",
  FACEBOOK: "Facebook",
  BROWSER: "Browser Share",
  SMS: "sms",
  GUEST_LINK: "Copy Guest Link",
  PUBLIC_LINK: "Copy Invite Link",
});

const SHARE_ACTIONS = Object.freeze({
  ORIGINAL: "original",
  UPDATE: "update",
});

const EDIT_SUBSCRIPTION_ACTIONS = Object.freeze({
  KEEP_RENEWAL: "keep renewal",
  CANCEL_RENEWAL: "cancel renewal",
  ENABLE_RENEWAL: "enable renewal",
});

const UPLOAD_SOURCES = Object.freeze({
  FACEBOOK: "Facebook",
  WEBCAM: "Webcam",
  USE_LAST_PHOTO: "Use Last Photo",
  BROWSE_PHOTOS: "Browse Photos",
  TAKE_PHOTO: "Take Photo",
});

const FOUND_ACTIONS = Object.freeze({
  OK: "Accept",
  SKIP: "Skip",
  EDIT: "Edit",
});

const INVITE_UPDATED_ACTIONS = Object.freeze({
  EVENT_DETAILS_UPDATE: "event details update",
  GUEST_UPDATE: "guest update",
});

const LOGIN_SIGNUP_METHODS = Object.freeze({
  EMAIL: "email",
  FACEBOOK: "facebook",
  APPLE: "apple",
  GOOGLE: "google",
});

const SIGNUP_CLICK_METHODS = Object.freeze({
  EMAIL: "signup-email",
  FACEBOOK: "signup-facebook",
  APPLE: "signup-apple",
  GOOGLE: "signup-google",
});

const MORPHABLE_PACK_PREVIEW_ACTIONS = Object.freeze({
  GENERATE: "generate",
  CHANGE_SKIN: "change_skin",
  CHANGE_PHOTO: "change_photo",
});

const MORPHABLE_PACK_CONFIRMATION_ACTIONS = Object.freeze({
  CONFIRM_SKIN_LIGHT: "confirm_skin_light",
  CONFIRM_SKIN_MEDIUM: "confirm_skin_medium",
  CONFIRM_SKIN_DARK: "confirm_skin_dark",
  USE_ANOTHER_FACE: "change_photo",
});

const MORPHABLE_PACK_CONFIRMATION_ACTIONS_KEYS = Object.freeze({
  [SKIN_COLORS.light]: "CONFIRM_SKIN_LIGHT",
  [SKIN_COLORS.medium]: "CONFIRM_SKIN_MEDIUM",
  [SKIN_COLORS.dark]: "CONFIRM_SKIN_DARK",
});

const VIEW_IMAGE_ACTIONS = Object.freeze({
  SHARE: "share",
  DOWNLOAD: "download",
});

const PAYMENT_METHODS = Object.freeze({
  RECURLY: "recurly",
  PAYPAL: "paypal",
  APPLEPAY: "applepay",
});

export {
  SHARE_METHODS,
  SHARE_ACTIONS,
  EDIT_SUBSCRIPTION_ACTIONS,
  UPLOAD_SOURCES,
  FOUND_ACTIONS,
  INVITE_UPDATED_ACTIONS,
  LOGIN_SIGNUP_METHODS,
  SIGNUP_CLICK_METHODS,
  MORPHABLE_PACK_PREVIEW_ACTIONS,
  MORPHABLE_PACK_CONFIRMATION_ACTIONS,
  MORPHABLE_PACK_CONFIRMATION_ACTIONS_KEYS,
  VIEW_IMAGE_ACTIONS,
  PAYMENT_METHODS,
};
