import { isNil } from "ramda";

import { trackMakeEvent, IN_FLOW_REGISTRATION } from "utils/makeEventUtils";
import { trackCordialLoginRegister } from "utils/cordialUtils";
import { sendRegistrationSuccessEvent } from "utils/gtmUtils";
import {
  signUpEvent,
  loginEvent,
  viewLoginEvent,
  clickLoginEvent,
  viewSignUpEvent,
  clickSignUpEvent,
} from "tracking/jibjab/tracking";
import {
  trackSignUpSuccess,
  trackLoginSuccess,
  trackViewLogin,
  trackLoginClick,
  trackClickSignUp,
  trackViewSignUp,
} from "tracking/google/analytics";
import { trackCompleteRegistration } from "tracking/facebook/conversion";
import { getEcard, getInFlow, getTemplateGroup, isMake } from "utils/lastEcardUtil";

const useTrackAccountEvents = () => {
  const trackSignUpEvents = (user, method) => {
    const currentUrl = window.location.href;
    const ecard = getEcard();
    const inFlow = getInFlow();
    const templateGroup = getTemplateGroup();

    trackCordialLoginRegister(user?.email);
    trackSignUpSuccess(method, inFlow, templateGroup);
    signUpEvent(user, method, templateGroup);
    trackCompleteRegistration(user, currentUrl);
    sendRegistrationSuccessEvent(user);
    if (!isNil(ecard) && isMake()) {
      trackMakeEvent(ecard, IN_FLOW_REGISTRATION);
    }
  };

  const trackLoginEvents = (user, method) => {
    const inFlow = getInFlow();
    const templateGroup = getTemplateGroup();

    trackCordialLoginRegister(user?.email);
    loginEvent(user, method, templateGroup);
    trackLoginSuccess(method, inFlow, templateGroup);
  };

  const trackViewSignUpEvents = () => {
    viewSignUpEvent();
    trackViewSignUp();
  };

  const trackClickSignUpEvents = (method) => {
    trackClickSignUp(method);
    clickSignUpEvent(method);
  };

  const trackViewLoginEvents = () => {
    viewLoginEvent();
    trackViewLogin();
  };

  const trackClickLoginEvents = (method) => {
    clickLoginEvent(method);
    trackLoginClick(method);
  };

  return {
    trackViewSignUpEvents,
    trackSignUpEvents,
    trackLoginEvents,
    trackViewLoginEvents,
    trackClickLoginEvents,
    trackClickSignUpEvents,
  };
};

export default useTrackAccountEvents;
