import { useSelector } from "react-redux";

import useNotifications from "hooks/useNotifications";
import useLastEcard from "hooks/useLastEcard";
import useRouter from "hooks/useRouter";
import MorphablePackGroupPresenter from "presenters/MorphablePackGroupPresenter";
import MorphedPackPresenter from "presenters/MorphedPackPresenter";
import { PACK_ERROR_MESSAGES, PACK_ERROR_CODES, PACK_ERROR_POINTERS } from "enums/syai";
import { appRoutes } from "routes";
import { jjLogger } from "utils/logUtils";

const useSYAIErrorHandler = () => {
  const { addErrorNotification } = useNotifications();
  const { push } = useRouter();
  const { ecard } = useLastEcard();
  const { morphedPack } = useSelector((state) => state.MorphedPackSlice);

  const notifyAndRedirect = (message, path) => {
    addErrorNotification(message);
    push(path);
  };

  const handleStatusError = (errorCode, morphablePackGroupSlug) => {
    const pack = morphedPack || ecard;
    const packGroupSlug =
      morphablePackGroupSlug || MorphablePackGroupPresenter.id(MorphedPackPresenter.morphablePackGroup(pack));

    switch (errorCode) {
      case PACK_ERROR_CODES.INVALID_SOURCE_PHOTO:
        notifyAndRedirect(PACK_ERROR_MESSAGES.INVALID_SOURCE_PHOTO, appRoutes.morphablePackGroupPath(packGroupSlug));
        break;

      case PACK_ERROR_CODES.INVALID_TARGET_PHOTO:
        notifyAndRedirect(PACK_ERROR_MESSAGES.INVALID_TARGET_PHOTO, appRoutes.starringYouAIPath());
        break;

      case PACK_ERROR_CODES.INTERNAL_API_FAILURE:
        notifyAndRedirect(PACK_ERROR_MESSAGES.INTERNAL_API_FAILURE, appRoutes.starringYouAIPath());
        break;

      default:
        break;
    }
  };

  const handlePhotoError = (packGroupSlug) => {
    notifyAndRedirect(PACK_ERROR_MESSAGES.SOURCE_PHOTO_FAILD_LOAD, appRoutes.morphablePackGroupPath(packGroupSlug));
  };

  const pollingLimitReached = () => {
    jjLogger.logError("useMorphedPack.js: pollingLimitReached() Polling limit reached");
    handleStatusError(PACK_ERROR_CODES.INTERNAL_API_FAILURE);
  };

  const handleMorphedPackError = (error, morphablePackGroupSlug) => {
    switch (error) {
      case PACK_ERROR_POINTERS.SOURCE_PHOTO:
      case PACK_ERROR_POINTERS.PHOTO_TYPE:
        handleStatusError(PACK_ERROR_CODES.INVALID_SOURCE_PHOTO, morphablePackGroupSlug);
        break;
      case PACK_ERROR_POINTERS.PHOTO_PACK_SLUG:
        handleStatusError(PACK_ERROR_CODES.INVALID_TARGET_PHOTO, morphablePackGroupSlug);
        break;
      default:
        break;
    }
  };

  return {
    pollingLimitReached,
    handleStatusError,
    handleMorphedPackError,
    notifyAndRedirect,
    handlePhotoError,
  };
};

export default useSYAIErrorHandler;
