const MALE_GENDERS = {
  MALE: "male",
  MEN: "men",
};

const FEMALE_GENDERS = {
  FEMALE: "female",
  WOMEN: "women",
};

const GENDER_PRESENTERS = {
  FEMALE: "Female",
  MALE: "Male",
};

Object.freeze(MALE_GENDERS);
Object.freeze(FEMALE_GENDERS);
Object.freeze(GENDER_PRESENTERS);

export { GENDER_PRESENTERS, MALE_GENDERS, FEMALE_GENDERS };
