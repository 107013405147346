import LogoWatermark from "assets/images/logo-watermark.png";

const getImageHandlerUrl = (path) => `${process.env.IMAGE_HANDLER_HOST}/${path}`;
const getContentfulImageHandlerUrl = (path) => `${process.env.CONTENTFUL_IMAGE_HANDLER_HOST}/${path}`;

export const BUCKET_LIST = {
  packs: process.env.WEBPACK_ASSETS_AWS_BUCKET,
  image_assets: process.env.UGC_ASSETS_AWS_BUCKET,
  contentful: process.env.CONTENTFUL_UGC_ASSETS_AWS_BUCKET,
  pills: process.env.UGC_ASSETS_AWS_BUCKET,
  heads: process.env.UGC_ASSETS_AWS_BUCKET,
  ugc: process.env.AWS_UGC_BUCKET,
};

export const key = (src) => {
  if (!src) return "";

  let path = src;
  if (src.includes("localhost")) {
    path = src.split("localhost:3000/").pop();
  } else if (src.includes("assets.jibjab.dev")) {
    path = src.split("assets.jibjab.dev/").pop();
  } else if (src.includes("jibjab.com/")) {
    path = src.split("jibjab.com/").pop();
  }

  return path.startsWith("/") ? path.substring(1) : path;
};

export const bucket = (src) => {
  const index = key(src).split("/");

  if (!index[0]) return null;

  return BUCKET_LIST[index[0]];
};

export const getImageUrl = (src, { newWidth, newHeight, watermark = false }) => {
  const bucketToUse = bucket(src);
  const keyToUse = key(src);
  if (!keyToUse || !bucketToUse) return src;

  const data = {
    bucket: bucketToUse,
    key: keyToUse,
    edits: {
      resize: {
        fit: "cover",
        withoutEnlargement: true,
      },
      filters: [],
    },
  };

  if (newWidth) {
    data.edits.resize.width = newWidth;
  }

  if (newHeight) {
    data.edits.resize.height = newHeight;
  }

  if (watermark) {
    data.edits.overlayWith = {
      bucket: process.env.WEBPACK_ASSETS_AWS_BUCKET,
      key: key(LogoWatermark),
      alpha: 1,
      wRatio: 10,
      hRatio: 6,
      options: {
        top: "-2p",
        left: "-2p",
      },
    };
  }

  const isContentful = data.key.split("/")[0] === "contentful";
  const newPath = window.btoa(unescape(encodeURIComponent(JSON.stringify(data))));

  return isContentful ? getContentfulImageHandlerUrl(newPath) : getImageHandlerUrl(newPath);
};
