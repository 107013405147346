import { isNil } from "ramda";

import { PAYMENT_METHODS } from "enums";
import MakePresenter from "presenters/MakePresenter";
import EcardPresenter from "presenters/EcardPresenter";
import UserPresenter from "presenters/UserPresenter";
import { jjLogger } from "utils/logUtils";
import { trackMakeEvent, IN_FLOW_SUBSCRIPTION_PURCHASE } from "utils/makeEventUtils";
import { subscriptionPurchaseEvent } from "tracking/jibjab/faceswap";
import { trackPurchase as trackPurchaseFacebook } from "tracking/facebook/conversion";
import { trackCordialSubscriptionEvent, trackCordialOrder } from "utils/cordialUtils";
import { sendPaymentSuccessEvent as trackPurchaseGTM } from "utils/gtmUtils";
import { trackPurchaseSuccess as trackPurchaseGA, trackViewPaygate } from "tracking/google/analytics";
import { purchaseEvent as trackPurchaseJJ, viewPaygateEvent } from "tracking/jibjab/tracking";

import useAlgoliaEvents from "./useAlgoliaEvents";
import useAlgoliaTracker from "./useAlgoliaTracker";
import useFmvr from "./useFmvr";
import useMakes from "./useMakes";
import useLastEcard from "./useLastEcard";
import useUtmsParams from "./useUtmsParams";
import useCJEvent from "./useCJEvent";

const usePaymentEvents = () => {
  const { trackEventAfterSearchAndPurchase } = useAlgoliaEvents();
  const { hitSelected } = useAlgoliaTracker();
  const { make, updateMake } = useMakes();
  const { inFlow, ecard, isMake, templateGroup, isMorphable } = useLastEcard();
  const { createFmvr } = useFmvr();
  const { checkUtmsStorage, trackCampaignAttribution } = useUtmsParams();
  const { trackEvent: trackPurchaseCJ } = useCJEvent();

  const onMorphablePurchase = (user) => {
    if (!user) {
      jjLogger.logError("usePaymentEvents.js: onSuccessfulInFlowPayment. Missing user parameter");
      return;
    }

    subscriptionPurchaseEvent({ user, morphedPack: ecard });
  };

  const onMakePurchase = async () => {
    await trackMakeEvent(ecard, IN_FLOW_SUBSCRIPTION_PURCHASE);
    await updateMake(ecard);
    createFmvr(ecard, templateGroup);
  };

  const onSuccessfulInFlowPayment = async (user) => {
    try {
      if (ecard) {
        if (isMorphable) onMorphablePurchase(user);
        if (isMake) await onMakePurchase();

        const algoliaId = EcardPresenter.algoliaId(ecard);
        if (algoliaId === hitSelected?.objectID) trackEventAfterSearchAndPurchase(hitSelected);
      }
    } catch (error) {
      let errorLog = "usePaymentEvents.js: onSuccessfulInFlowPayment(): error: ";

      if (Array.isArray(error)) {
        error.forEach((err) => {
          errorLog += err;
        });
      } else {
        errorLog += error;
      }

      jjLogger.logError(errorLog);
    }
  };

  const purchaseSuccessEvents = async (method, user, offer) => {
    if (!Object.values(PAYMENT_METHODS).includes(method)) {
      jjLogger.logError(`usePaymentEvents.js: purchaseSuccessEvents(): Strange payment method: ${method}`);
    }

    const utmParams = checkUtmsStorage();
    const template = EcardPresenter.template(ecard, templateGroup);
    const subscriptionID = UserPresenter.activeSubscription(user).id;
    const { currency, id: offerId } = offer;
    const offerPrice = parseFloat(offer.price);

    await onSuccessfulInFlowPayment(user);

    if (!isNil(utmParams)) trackCampaignAttribution(utmParams, user?.id);

    trackPurchaseFacebook(user, offer);
    trackCordialSubscriptionEvent(offer);
    trackCordialOrder(offer);
    trackPurchaseGA(method, inFlow, offer, user, template, templateGroup);
    trackPurchaseJJ(user, offer);
    trackPurchaseGTM(user, method, offer, subscriptionID, MakePresenter.template(make));
    trackPurchaseCJ(subscriptionID, currency, offerPrice, offerId);
  };

  const trackViewPaygateEvents = (user) => {
    viewPaygateEvent(user);
    trackViewPaygate();
  };

  return { purchaseSuccessEvents, trackViewPaygateEvents };
};

export default usePaymentEvents;
