/* global gtag */
/* global DetectUserAgent */
import { useEffect, useMemo, useContext } from "react";

import { FETCH_STATUSES } from "enums";
import { ExperimentContext } from "contexts/experiment-context";
import UserPresenter from "presenters/UserPresenter";
import MakePresenter from "presenters/MakePresenter";
import useUsers from "hooks/useUsers";
import useMakes from "hooks/useMakes";
import { jjLogger } from "utils/logUtils";

const useGA4 = () => {
  const { currentUser, currentUserLoadingStatus } = useUsers();
  const { make } = useMakes();
  const { experimentId } = useContext(ExperimentContext);

  const configParams = useMemo(() => {
    const config = {
      user_id: currentUser ? currentUser.id : null,
      user_properties: {
        loggedIn: UserPresenter.isRegistered(currentUser) ? "yes" : "no",
        statusMembership: UserPresenter.isPaid(currentUser) ? "member" : "nonMember",
        userIsRecipient: MakePresenter.isRecipient(make) ? "yes" : "no",
        experiment_id: experimentId || null,
        version: process.env.GIT_BRANCH,
      },
    };

    return config;
  }, [currentUser, make, experimentId]);

  useEffect(() => {
    if (DetectUserAgent.isPrerender()) return;

    if (currentUserLoadingStatus === FETCH_STATUSES.fulfilled) {
      jjLogger.log(`useGA4 - useEffect - gtag("set") - ${JSON.stringify({ configParams })}`);

      gtag("set", "user_properties", configParams.user_properties);
      gtag("set", "user_id", configParams.user_id);
    }
  }, [configParams, currentUserLoadingStatus]);
};

export default useGA4;
