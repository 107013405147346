import { getInFlow, getTemplateGroup } from "utils/lastEcardUtil";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import { jjLogger } from "utils/logUtils";

export const getContentTypeInFlow = () => {
  const inFlow = getInFlow();
  const templateGroup = getTemplateGroup();

  if (!inFlow) return undefined;

  if (!templateGroup) {
    jjLogger.logError("getContentTypeInFlow: Template Group is not set even though in flow");
    return undefined;
  }

  return TemplateGroupPresenter.templateGroupType(templateGroup);
};
