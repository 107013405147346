import PropTypes from "prop-types";
import { isNil, last } from "ramda";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    id: PropTypes.number,
    role: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    preferenceEmail: PropTypes.bool,
    isPaid: PropTypes.bool,
    createdAt: PropTypes.number,
  },
  {
    noUser(u) {
      return isNil(u);
    },
    isAnonymous(u) {
      return this.role(u) === "anonymous";
    },
    notRegistered(u) {
      return this.noUser(u) || this.isAnonymous(u);
    },
    isRegistered(u) {
      return !this.notRegistered(u);
    },
    notPaid(u) {
      return !this.isPaid(u);
    },
    analyticsStatus(u) {
      if (this.noUser(u)) return undefined;
      if (this.isAnonymous(u)) return "anonymous";
      return this.isPaid(u) ? "paid" : "not_paid";
    },
    activeSubscription(u) {
      if (this.noUser(u)) {
        return null;
      }

      const activeSubscriptions = u.subscriptions.filter((sub) => sub.active);
      return activeSubscriptions.length ? last(activeSubscriptions) : null;
    },
  },
);
