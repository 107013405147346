import { FACE_PAGE_FLOWS, UPLOAD_SOURCES } from "enums";
import { appRoutes } from "routes";
import { allToBase64, rotateAndScale } from "utils/imageUtils";
import { trackPhotoUploadSuccess } from "tracking/google/analytics";

import useFaces from "./useFaces";
import useRouter from "./useRouter";

const useFacebookUploadFunctions = (closeModalFacebook) => {
  const { pushWithSave } = useRouter();
  const { setPhoto, closeModal, clearPhoto, clearFoundFaces, resetAdded, setMode, clearFacesToAddPerson } = useFaces();
  const goToFace = () => pushWithSave(appRoutes.facePath());

  const cleanData = () => {
    clearPhoto();
    clearFoundFaces();
    resetAdded();
    clearFacesToAddPerson();
  };

  const prepareAndUpdatePhoto = (base64, newMode) => {
    setPhoto(base64);
    setMode(newMode);
    goToFace();
    closeModal();
    closeModalFacebook();
  };

  const setAndDetectPhoto = (base64) => {
    cleanData();
    prepareAndUpdatePhoto(base64, FACE_PAGE_FLOWS.LOADING);
  };

  const handleFacebookUploadSuccess = (photoUrl, templateGroup) => {
    if (photoUrl) {
      trackPhotoUploadSuccess(UPLOAD_SOURCES.FACEBOOK, templateGroup);
      allToBase64(`${photoUrl}&redirect=false`)
        .then(rotateAndScale)
        .then(setAndDetectPhoto);
    }
  };

  const handleFacebookSourcePhotoUploadSuccess = (photoUrl, templateGroup) => {
    if (photoUrl) {
      trackPhotoUploadSuccess(UPLOAD_SOURCES.FACEBOOK, templateGroup);
      return allToBase64(`${photoUrl}&redirect=false`).then(rotateAndScale);
    }

    return null;
  };

  return {
    handleFacebookUploadSuccess,
    handleFacebookSourcePhotoUploadSuccess,
  };
};

export default useFacebookUploadFunctions;
