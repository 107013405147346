export const GOOGLE_BUTTON_ID = "google-connect-btn";
export const GOOGLE_BUTTON_SCRIPT_ID = "google-client-sdk";
export const GOOGLE_BUTTON_SCRIPT_SRC = "https://accounts.google.com/gsi/client";
export const GOOGLE_BUTTON_WIDTH_MOBILE = "270px";
export const GOOGLE_BUTTON_WIDTH_DESKTOP = "400px";
export const GOOGLE_BUTTON_CONTINUE = {
  theme: "outline",
  size: "large",
  shape: "pill",
  text: "continue_with",
};
export const GOOGLE_BUTTON_SIGN_UP = {
  theme: "outline",
  size: "large",
  shape: "pill",
  text: "signup_with",
};
