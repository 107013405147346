/* global DetectUserAgent */
import { isNil } from "ramda";

import { jjLogger } from "utils/logUtils";
import { appRoutes } from "routes";

const PERMISSIONS_SCOPE = "public_profile,email,user_photos";
const DEFAULT_PERMISSION_USER_PHOTOS = "user_photos";

const encodeParams = (params) =>
  `?${Object.keys(params)
    .map((param) => `${param}=${encodeURIComponent(params[param])}`)
    .join("&")}`;

const getParamsApi = (token) => {
  const isInAppBrowser = DetectUserAgent.isFBInAppBrowser() || DetectUserAgent.isTikTokBrowser();
  return isInAppBrowser ? `access_token=${token}` : "";
};

const logMsg = (msg) => {
  jjLogger.log(`facebookUtils.js: ${msg}`);
};

const logDebugMsg = (msg) => {
  jjLogger.logDebug(`facebookUtils.js: ${msg}`);
};

const logResponse = (method, type, response) => {
  logMsg(`${method}(): response ${type}: ${JSON.stringify(response)}`);
};

const logDebugResponse = (method, type, response) => {
  logDebugMsg(`${method}(): response ${type}: ${JSON.stringify(response)}`);
};

const loadScript = (attempt = 1, maxAttempts = 3, onSuccess, onFail) => {
  logDebugMsg(`loadScript() - Attempt ${attempt} to load Facebook SDK`);

  const id = "facebook-jssdk";

  let js = document.getElementById(id);
  if (js) {
    if (attempt === 1) {
      logDebugMsg("loadScript() - Facebook SDK script already exists from a previous attempt, removing for retry.");
      js.remove();
    } else {
      logDebugMsg("loadScript() - Facebook SDK script already exists, no need to load again.");
      return;
    }
  }

  js = document.createElement("script");
  js.id = id;
  js.crossOrigin = "anonymous";
  js.async = true;
  js.defer = true;
  js.src = "https://connect.facebook.net/en_US/sdk.js";

  js.onload = () => {
    logDebugMsg(`loadScript() - onLoad: Facebook SDK script loaded successfully on attempt ${attempt}`);
    onSuccess();
  };

  js.onerror = () => {
    logDebugMsg(`loadScript() - onError: Failed to load the Facebook SDK script on attempt ${attempt}`);
    js.remove();

    if (attempt < maxAttempts) {
      logDebugMsg(`loadScript() - onError: Retrying to load Facebook SDK... Attempt ${attempt + 1} of ${maxAttempts}`);
      setTimeout(() => loadScript(attempt + 1, maxAttempts), 2000 * attempt);
    } else {
      jjLogger.logError("loadScript() - onError: Max attempts reached. Unable to load the Facebook SDK script.");
      onFail(new Error("Failed to load the Facebook SDK script after multiple attempts."));
    }
  };

  document.body.appendChild(js);
};

export const init = (fbAppId, onChangeInitialized) => {
  return new Promise((resolve, reject) => {
    const attempt = 1;
    const maxAttempts = 3;

    if (typeof window?.FB !== "undefined") {
      logDebugMsg("init(): window.FB already initialized");
      onChangeInitialized(true);
      resolve();
    } else {
      logDebugMsg(`init() - window.FB not initialized yet`);

      window.fbAsyncInit = () => {
        window.FB.init({
          appId: fbAppId,
          autoLogAppEvents: true,
          xfbml: true,
          version: "v17.0",
        });

        logDebugMsg("init(): window.FB initialized");
        onChangeInitialized(true);
        resolve();
      };

      loadScript(attempt, maxAttempts, resolve, reject);
    }
  });
};

export const validatePermissions = (currentStatus, accessToken) => {
  return new Promise((resolve, reject) => {
    if (currentStatus === "connected" || accessToken) {
      logDebugMsg("validatePermissions(): calling window.FB.api");
      window.FB.api(`/me/permissions?${getParamsApi(accessToken)}`, (response) => {
        if (response && response?.error) {
          logDebugResponse("validatePermissions", "error", response);
          reject(response.error);
        }

        const permissions = response?.data;
        const userPhotosPermissions = permissions?.find(
          ({ permission }) => permission === DEFAULT_PERMISSION_USER_PHOTOS,
        );

        if (userPhotosPermissions?.status === "granted") {
          logDebugResponse("validatePermissions", "success", response);
          resolve("connected");
        } else {
          logDebugResponse("validatePermissions", "error", response);
          reject(new Error(`User photo permission status was "${userPhotosPermissions?.status}" instead of "granted"`));
        }
      });
    } else {
      const msg = "Facebook user does not seem connected";
      logDebugMsg(`validatePermissions(): ${msg}: currentStatus: ${currentStatus} accessToken: ${accessToken}`);
      reject(new Error(msg));
    }
  });
};

export const getNextPage = (nextPage, setNextPage) => {
  return new Promise((resolve, reject) => {
    logDebugMsg("getNextPage(): calling window.FB.api");
    window.FB.api(nextPage, (response) => {
      if (response && response?.error) {
        logDebugResponse("getNextPage", "error", response);
        reject();
      } else {
        logDebugResponse("getNextPage", "success", response);
        resolve(response.data);
        setNextPage(response?.paging?.next);
      }
    });
  });
};

export const checkLoggedin = () => {
  return new Promise((resolve, reject) => {
    logDebugMsg("checkLoggedin(): calling window.FB.getLoginStatus");
    window.FB.getLoginStatus((response) => {
      if (response && response?.error) {
        logResponse("checkLoggedin", "error", response);
        reject();
      } else {
        logMsg("checkLoggedin(): response success");
        logDebugResponse("checkLoggedin", "success", response);
        resolve(response.status);
      }
    });
  });
};

export const getImagesOfAlbum = (albumId, setNext, accessToken) => {
  return new Promise((resolve, reject) => {
    logDebugMsg("getImagesOfAlbum(): calling window.FB.api");
    window.FB.api(
      `/${albumId}/photos?${getParamsApi(accessToken)}`,
      (response) => {
        if (response && response?.error) {
          logDebugResponse("getImagesOfAlbum", "error", response);
          reject();
        } else {
          logDebugResponse("getImagesOfAlbum", "success", response);
          resolve(response.data);
          setNext(response?.paging?.next);
        }
      },
      {
        fields: "images,created_time,name",
      },
    );
  });
};

export const getAllAlbums = (setNext, accessToken) => {
  return new Promise((resolve, reject) => {
    logDebugMsg("getAllAlbums(): calling window.FB.api");
    window.FB.api(
      `/me/albums?${getParamsApi(accessToken)}`,
      (response) => {
        if (response && response?.error) {
          logDebugResponse("getAllAlbums", "error", response);
          reject();
        } else {
          logDebugResponse("getAllAlbums", "success", response);
          resolve(response?.data);
          setNext(response?.paging?.next);
        }
      },
      {
        fields: "id,name,cover_photo{id,images}",
      },
    );
  });
};

export const getAllUserInfo = (accessToken) => {
  return new Promise((resolve, reject) => {
    logDebugMsg("getUserInfo(): calling window.FB.api");
    window.FB.api(
      accessToken ? `/me?${getParamsApi(accessToken)}` : "/me",
      (response) => {
        if (response && response?.error) {
          logDebugResponse("getUserInfo", "error", response);
          reject();
        } else {
          logDebugResponse("getUserInfo", "success", response);
          resolve(response);
        }
      },
      {
        fields: "id,name,email",
      },
    );
  });
};

export const login = () => {
  return new Promise((resolve, reject) => {
    logDebugMsg("login(): calling window.FB.login");
    window.FB.login(
      (response) => {
        if ((response && response?.error) || isNil(response?.authResponse)) {
          logDebugResponse("login", "error", response);
          reject();
        } else {
          logMsg("login(): response success");
          logDebugResponse("login", "success", response);
          resolve(response);
        }
      },
      {
        scope: PERMISSIONS_SCOPE,
        auth_type: "rerequest",
      },
    );
  });
};

export const customLogin = (fbAppId) => {
  const redirectPath = `https://${window.location.host + appRoutes.oAuthRedirectPath()}`;
  const currentUrl = new URL(window.location.href);
  const urlParams = new URLSearchParams(currentUrl.search);
  urlParams.set("fbRedirect", "true");
  currentUrl.search = urlParams.toString();

  const params = {
    client_id: fbAppId,
    redirect_uri: redirectPath,
    state: `{ "from": "${currentUrl.href}" }`,
    scope: PERMISSIONS_SCOPE,
    response_type: "token",
  };
  const url = `https://www.facebook.com/dialog/oauth${encodeParams(params)}`;

  logDebugMsg(`customLogin(): routing to ${url}`);
  window.location.replace(url);
};
