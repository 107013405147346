/* global DetectUserAgent */
import { useState } from "react";
import { Watermark } from "webgl-render-library";

import useRouter from "hooks/useRouter";
import useNotifications from "hooks/useNotifications";
import { useLoadingActions } from "store/LoadingSlice";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import MakePresenter from "presenters/MakePresenter";
import FmvrPresenter from "presenters/FmvrPresenter";
import FmvrRepository from "repositories/FmvrRepository";
import { noWebGL } from "utils/WebglUtils";
import { castingsToActors } from "utils/makeUtils";
import { jjLogger } from "utils/logUtils";
import { serializeFunction, deserialize, isString } from "utils/storeUtils";
import { fmvrAttributes } from "utils/fmvrUtils";
import { videoAttributes } from "utils/videoPlayerUtils";
import { trackMakeViewError } from "tracking/google/analytics";
import watermarkImage from "assets/images/logo-watermark.png";

export const PLAYER_TYPES = {
  HTMLPlayer: "htmlplayer",
  WEBGL: "webgl",
};

const labelFunction = () => "Something went wrong. Please refresh the page";

const MAX_RENDER_INTERVALS = 60;

const usePlayer = ({ make, templateGroup }) => {
  const {
    camelizedQuery: { rendering },
  } = useRouter();
  const [fmvr, setFmvr] = useState(null);
  const [fmvrProgress, setFmvrProgress] = useState(null);
  const [pollInterval, setPollInterval] = useState(null);
  const checkingForRender = fmvr == null;
  const hasFmvr = !checkingForRender && isString(FmvrPresenter.url(fmvr));
  const { addErrorNotification } = useNotifications();
  const { setLabelFunc } = useLoadingActions();
  const { loop, controls, autoplay, muted, isVertical, aspectRatio } = videoAttributes(templateGroup);
  const isStraightUp = TemplateGroupPresenter.isStraightUp(templateGroup);

  const getHTMLPlayerParams = () => {
    if (!make || !templateGroup) return {};

    const src = isStraightUp ? TemplateGroupPresenter.previewVideo(templateGroup) : FmvrPresenter.url(fmvr);
    return {
      src,
      poster: MakePresenter.thumbnail(make),
      loop,
      autoplay,
      muted,
      controls,
      isVertical,
      aspectRatio,
      onError: () => {
        trackMakeViewError("FMVR");
      },
    };
  };

  const id = make && MakePresenter.id(make);

  const getWebGlParams = () => {
    if (!make || !templateGroup) return {};

    const webglPositionData = MakePresenter.webglPositionData(make, templateGroup);
    const webglFeatureVideo = MakePresenter.webglFeatureVideo(make, templateGroup);
    const positionData = MakePresenter.positionData(make, templateGroup);
    const manifest = MakePresenter.manifest(make, templateGroup);
    const hasStreaming = MakePresenter.hasStreamingAssets(make, templateGroup);
    const watermark = new Watermark(watermarkImage);

    return {
      poster: MakePresenter.thumbnail(make),
      feature: hasStreaming ? manifest : webglFeatureVideo,
      positionData: hasStreaming ? positionData : webglPositionData,
      actors: castingsToActors(MakePresenter.castings(make)),
      loop,
      autoplay,
      muted,
      controls,
      watermark,
      isVertical,
      aspectRatio,
    };
  };

  const detectPlayerType = () => {
    if (!make || !templateGroup) return false;

    switch (true) {
      case TemplateGroupPresenter.isStraightUp(templateGroup):
        return PLAYER_TYPES.HTMLPlayer;
      case rendering && [PLAYER_TYPES.HTMLPlayer, PLAYER_TYPES.WEBGL].includes(rendering):
        return rendering;
      case noWebGL():
        return PLAYER_TYPES.HTMLPlayer;
      case hasFmvr:
        return PLAYER_TYPES.HTMLPlayer;
      case DetectUserAgent.isFireFox() && DetectUserAgent.isAndroid():
        return PLAYER_TYPES.HTMLPlayer;
      default:
        return PLAYER_TYPES.WEBGL;
    }
  };

  const getPlayerParams = (playerType) => {
    if (playerType === PLAYER_TYPES.HTMLPlayer) return getHTMLPlayerParams();
    if (playerType === PLAYER_TYPES.WEBGL) return getWebGlParams();
    return null;
  };

  const stopPoll = () => setPollInterval((prevInteval) => clearInterval(prevInteval));

  const fmvrRequestError = (message) => {
    addErrorNotification();
    setLabelFunc(serializeFunction(labelFunction));
    jjLogger.logError(message);
    stopPoll();
  };

  const createFmvr = () =>
    FmvrRepository.create(id, fmvrAttributes(make, templateGroup)).then(
      ({ data }) => {
        const result = deserialize(data);
        if (result && result.url) setFmvr(result);

        return Promise.resolve(result);
      },
      (error) => fmvrRequestError(`FMVR Request Error: ${error}`),
    );

  const fetchFmvr = () =>
    FmvrRepository.show(id).then(
      ({ data }) => {
        const result = deserialize(data);
        setFmvr(result);
        return Promise.resolve(result);
      },
      (error) => {
        setFmvr(false);
        return Promise.reject(error);
      },
    );

  const pollFmvr = (time = 2000) =>
    new Promise((resolve) => {
      let requestCount = MAX_RENDER_INTERVALS;
      const interval = setInterval(() => {
        fetchFmvr().then(
          (result) => {
            if (result?.progress) setFmvrProgress(parseFloat(result.progress));

            if (result?.url) {
              resolve(result.url);
            } else {
              requestCount -= 1;

              if (requestCount <= 0) {
                const waitTime = (time / 1000) * MAX_RENDER_INTERVALS;
                fmvrRequestError(`FMVR Request Error: Failed to get FMVR render request: Waited ${waitTime} seconds`);
              }
            }
          },
          (error) => fmvrRequestError(`FMVR Request Error: ${error}`),
        );
      }, time);
      setPollInterval(interval);
    });

  const hasData = (type, params) => {
    switch (true) {
      case PLAYER_TYPES.HTMLPlayer === type:
        return !!params?.src;
      case PLAYER_TYPES.WEBGL === type:
        return !!params?.feature && !!params?.actors;
      default:
        return false;
    }
  };

  return {
    detectPlayerType,
    PLAYER_TYPES,
    getPlayerParams,
    fetchFmvr,
    fmvr,
    fmvrProgress,
    checkingForRender,
    createFmvr,
    pollFmvr,
    stopPoll,
    pollInterval,
    hasData,
    setFmvr,
  };
};

export default usePlayer;
