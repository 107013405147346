export const COLORS = {
  cloudBurst: "#252D5F",
  havelockBlue: "#517BD3",
  heliotrope: "#C285F5",
  brightSun: "#FFCC43",
  mountainMeadow: "#1CC975",
  turquoise: "#47D4D7",
  darkTurquoise: "#00A2A6",
  paleTurquoise: "#B0F0F0",
  cornflowerBlue: "#51a0f7",
  chambray: "#3B5998",
  lightAqua: "#A3EAEB",
  flamingo: "#F05440",
  radicalRed: "#F9255D",
  melon: "#FFBCB3",
  dodgerBlue: "#5192FF",
  darkDodgerBlue: "#2E68FB",
  rajah: "#F7AA63",
  mauve: "#D5A9FB",
  persianPink: "#F578C2",
  classicPink: "#FBC9E7",
  persianPinkLight: "#F58CCA",
  malibu: "#79BBF7",
  porcelain: "#EEF2F3",
  cupid: "#FCC9D5",
  wildSand: "#F6F6F6",
  cadetBlue: "#B6BCC6",
  parisDaisy: "#FDF16F",
  gossip: "#CBF5A0",
  salmon: "#FF7979",
  casablanca: "#FBB94B",
  alto: "#D5D5D5",
  polar: "#edfbfb",
  botticelli: "#D5E5E9",
  wildWatermelon: "#FD4D7B",
  apricotPeach: "#FBD4B1",
  foam: "#D8F0FB",
  magicMint: "#9FEAC7",
  palePrim: "#FEF8B8",
  springGreen: "#54EDA7",
  quillGray: "#D8D8D8",
  sail: "#B1E2F7",
  navy: "#44458F",
  paleLilac: "#E5CBFC",
  sweetPink: "#FEA0A0",
  gray: "#eee",
  grayLight: "#929292",
  ribbonYellow: "#FFCE1E",
  charcoal: "#27272A",
};

const basePalette = {
  primary: {
    main: "#5080FF",
    light: "#385AB3",
    dark: "#80A2FF",
    contrastText: "white",
    gradient: `linear-gradient(135deg,${COLORS.turquoise},${COLORS.dodgerBlue})`,
  },
  secondary: {
    main: "#47D4D7",
    light: COLORS.darkTurquoise,
    dark: COLORS.lightAqua,
    purple: "#8967FD",
    contrastText: "white",
    gradient: `linear-gradient(135deg,${COLORS.malibu},${COLORS.havelockBlue})`,
  },
  warning: {
    main: COLORS.casablanca,
    contrastText: "white",
  },
  success: {
    main: COLORS.mountainMeadow,
    contrastText: "white",
  },
  neutral: {
    main: "white",
    dark: "#1A1D29",
    light: "#606171",
    black: "black",
  },
  dark: {
    main: COLORS.navy,
    contrastText: "white",
  },
  baseGradient: `linear-gradient(134deg,${COLORS.turquoise},${COLORS.dodgerBlue})`,
  delete: {
    main: COLORS.radicalRed,
    contrastText: "white",
  },
  disabled: {
    main: "#DADADD",
    contrastText: "#9E9FA8",
  },
  connect: {
    main: "#dadce0",
    contrastText: "white",
  },
  ...COLORS,
};

export default {
  ...COLORS,
  ...basePalette,
  primaryLight: {
    main: basePalette.primary.light,
    dark: basePalette.primary.main,
    contrastText: basePalette.primary.contrastText,
  },
  secondaryLight: {
    main: basePalette.secondary.light,
    dark: basePalette.secondary.main,
    contrastText: basePalette.secondary.contrastText,
  },
  primaryGradient: {
    main: basePalette.primary.main,
    contrastText: "white",
  },
  secondaryGradient: {
    main: basePalette.secondary.main,
    contrastText: "white",
  },
  inputGradient: {
    main: "rgba(203,169,251,.22)",
  },
  text: {
    main: basePalette.neutral.dark,
    light: basePalette.neutral.light,
  },
  categories: {
    holidays: basePalette.primary.main,
    greetings: basePalette.secondary.main,
    celebrate: basePalette.secondary.purple,
  },
  social: {
    facebook: COLORS.chambray,
  },
  placeholder: COLORS.porcelain,
  overlays: {
    blue: COLORS.dodgerBlue,
    darkBlue: COLORS.darkDodgerBlue,
    orange: COLORS.rajah,
    violin: COLORS.mauve,
    pink: COLORS.persianPink,
    green: COLORS.turquoise,
  },
  calendarRelations: {
    birthday: {
      background: "linear-gradient(180deg,#B1E2F7,#32A0FE)",
    },
    anniversary: {
      background: "linear-gradient(180deg,#B0F0F0,#58E0E0)",
    },
    valentines: {
      background: "linear-gradient(180deg,#E5CBFC,#DB68F4)",
    },
    mothersDay: {
      background: "linear-gradient(180deg,#FCC9D5,#FF82A0)",
    },
    fathersDay: {
      background: "linear-gradient(180deg,#FBD4B1,#FFA753)",
    },
  },
  relatives: {
    me: {
      background: COLORS.lightAqua,
      CTAbackground: "linear-gradient(180deg,#A3EAEB,#47D4D7)",
      profileBackground: "linear-gradient(180deg,#79bbf7 0,#b0fcf6 100%)",
    },
    mother: {
      background: COLORS.cupid,
      CTAbackground: "linear-gradient(180deg,#FCC9D5,#F578C2)",
      profileBackground: "linear-gradient(180deg,#f578c2 0,#fbc2e4 100%)",
    },
    father: {
      background: COLORS.apricotPeach,
      CTAbackground: "linear-gradient(180deg,#FFC76C,#FBA55D)",
      profileBackground: "linear-gradient(180deg,#f99535 0,#f7b06b 100%)",
    },
    pet: {
      background: COLORS.sweetPink,
      CTAbackground: "linear-gradient(180deg,#FABA61,#FF599B)",
      profileBackground: "linear-gradient(180deg,#ff7979 0,#f78686 100%)",
    },
    partner: {
      background: COLORS.parisDaisy,
      CTAbackground: "linear-gradient(180deg,#F9EC7D,#F5D43B)",
      profileBackground: "linear-gradient(180deg,#fad145 0,#fdf16f 100%)",
    },
    friend: {
      color: COLORS.chambray,
      background: COLORS.foam,
      CTAbackground: "linear-gradient(180deg,#FABA61,#FF599B)",
      profileBackground: "linear-gradient(180deg,#47d4d7 0,#fff46b 100%)",
    },
    family: {
      color: COLORS.chambray,
      background: COLORS.foam,
      CTAbackground: "linear-gradient(180deg,#FABA61,#FF599B)",
      profileBackground: "linear-gradient(180deg,#47d4d7 0,#fff46b 100%)",
    },
    default: {
      color: COLORS.chambray,
      background: COLORS.foam,
      CTAbackground: "linear-gradient(180deg,#A3EAEB,#47D4D7)",
      profileBackground: "linear-gradient(180deg,#47d4d7 0,#fff46b 100%)",
    },
  },
  player: {
    background: COLORS.cadetBlue,
    primary: basePalette.primary.main,
    secondary: basePalette.secondary.main,
    bright: basePalette.secondary.light,
  },
  searchTerms: {
    yellow: COLORS.parisDaisy,
    blue: COLORS.lightAqua,
    green: COLORS.gossip,
    pink: COLORS.persianPink,
    red: COLORS.salmon,
    violin: COLORS.mauve,
    orange: COLORS.casablanca,
  },
  backdrop: "rgba(0, 0, 0, 0.5)",
  makeBackgrounds: {
    default: "linear-gradient(0deg, #B0DFFC 0%, #79BBF7 100%)",
    christmas: "linear-gradient(0deg, #7DECED 0%, #47D4D7 100%)",
    valentines: "linear-gradient(0deg, #FBC2E4 0%, #F578C2 100%)",
    musicvideos: "linear-gradient(0deg, #FF599B 0%, #FABA61 100%)",
    halloween: "linear-gradient(0deg, #D1A5F7 0%, #957FFF 100%)",
    dance: "linear-gradient(0deg, #F9EC7D 0%, #F5D43B 100%)",
    thanksgiving: "linear-gradient(0deg, #FAD145 0%, #FBA55D 100%)",
    newyear: "linear-gradient(0deg, #D47BD2 0%, #77AFFD 100%)",
    love: "linear-gradient(0deg, #FBC2E4 0%, #F578C2 100%)",
    weddings: "linear-gradient(0deg, #FBC2E4 0%, #F578C2 100%)",
    anniversary: "linear-gradient(0deg, #FBC2E4 0%, #F578C2 100%)",
  },
  breadcrumbs: {
    active: basePalette.neutral.main,
    inactive: basePalette.secondary.dark,
  },
  datepickers: {
    birthday: COLORS.magicMint,
    anniversary: COLORS.palePrim,
    checkedMark: COLORS.springGreen,
    uncheckedMark: COLORS.quillGray,
  },
  calendarItemBackground: {
    birthday: COLORS.sail,
    valentinesDay: COLORS.paleLilac,
    anniversary: COLORS.paleTurquoise,
    mothersDay: COLORS.cupid,
    fathersDay: COLORS.apricotPeach,
  },
  avatar: `${basePalette.primary.main}30`,
  drawer: {
    main: "#F6F8FF",
  },
  table: {
    main: "#F1F1F1",
  },
  skinColors: {
    light: "#FEE7CC",
    medium: "#A57E59",
    dark: "#35261E",
  },
};
