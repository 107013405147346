import { useState } from "react";

import { LOGIN_SIGNUP_METHODS } from "enums";
import { jjLogger } from "utils/logUtils";
import useUsers from "hooks/useUsers";

const useSocialAuth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { thirdPartySignInSignUp } = useUsers();

  const handleSocialAuth = async (provider, response) => {
    jjLogger.logDebug(`useSocialAuth: provider: ${provider}, response: ${JSON.stringify(response)}`);

    if (provider === LOGIN_SIGNUP_METHODS.FACEBOOK && response?.status === "unknown") return null;

    setIsLoading(true);

    try {
      return await thirdPartySignInSignUp(provider, response);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSocialAuth, isSocialAuthLoading: isLoading };
};

export default useSocialAuth;
