import { useRef } from "react";

import { trackVideoProgress, trackVideoStart, trackVideoComplete } from "tracking/google/analytics";

export const PROGRESS_THRESHOLD = {
  START: 0.1,
  FIRST_QUARTER: 25,
  MID_POINT: 50,
  THIRD_QUARTER: 75,
  END: 99,
};

export const RANGE_LIMIT = 3;

const useVideoTracker = (templateGroup) => {
  const isVideoStarted = useRef(false);
  const is25PercentWatched = useRef(false);
  const is50PercentWatched = useRef(false);
  const is75PercentWatched = useRef(false);
  const isVideoCompleted = useRef(false);

  const calculateProgress = (e) => {
    const { currentTime, duration } = e.currentTarget;
    return Math.floor((currentTime / duration) * 100);
  };

  const handleResetsOnPlay = (e) => {
    const progress = calculateProgress(e);

    if (progress < PROGRESS_THRESHOLD.FIRST_QUARTER) {
      is25PercentWatched.current = false;
      is50PercentWatched.current = false;
      is75PercentWatched.current = false;
    } else if (progress < PROGRESS_THRESHOLD.MID_POINT) {
      is50PercentWatched.current = false;
      is75PercentWatched.current = false;
    } else if (progress < PROGRESS_THRESHOLD.THIRD_QUARTER) {
      is75PercentWatched.current = false;
    }
  };

  const trackStart = (e) => {
    const { currentTime } = e.currentTarget;
    if (currentTime < 1 && currentTime >= PROGRESS_THRESHOLD.START && !isVideoStarted.current) {
      isVideoStarted.current = true;
      trackVideoStart(e.currentTarget, templateGroup);
    }
  };

  const checkAndTrackProgress = (video, progress, threshold, loggedFlagRef) => {
    if (progress >= threshold && progress < threshold + RANGE_LIMIT && !loggedFlagRef.current) {
      const loggedFlag = loggedFlagRef;
      loggedFlag.current = true;
      trackVideoProgress(video, threshold, templateGroup);
    }
  };

  const trackProgress = (e) => {
    const { currentTime } = e.currentTarget;
    const progress = calculateProgress(e);

    if (currentTime <= 1) {
      is25PercentWatched.current = false;
      is50PercentWatched.current = false;
      is75PercentWatched.current = false;
      return;
    }

    checkAndTrackProgress(e.currentTarget, progress, PROGRESS_THRESHOLD.FIRST_QUARTER, is25PercentWatched);
    checkAndTrackProgress(e.currentTarget, progress, PROGRESS_THRESHOLD.MID_POINT, is50PercentWatched);
    checkAndTrackProgress(e.currentTarget, progress, PROGRESS_THRESHOLD.THIRD_QUARTER, is75PercentWatched);
  };

  const trackComplete = (e) => {
    const progress = calculateProgress(e);

    if (progress >= PROGRESS_THRESHOLD.END && !isVideoCompleted.current) {
      isVideoCompleted.current = true;
      trackVideoComplete(e.currentTarget, templateGroup);
    }
  };

  const resetProgressTracking = () => {
    isVideoStarted.current = false;
    is25PercentWatched.current = false;
    is50PercentWatched.current = false;
    is75PercentWatched.current = false;
    isVideoCompleted.current = false;
  };

  const handleTimeUpdateTrackers = (e) => {
    const { loop } = e.currentTarget;

    if (!loop) trackProgress(e);
    trackStart(e);
    trackComplete(e);
  };

  return {
    isVideoStarted,
    is25PercentWatched,
    is50PercentWatched,
    is75PercentWatched,
    isVideoCompleted,
    handleResetsOnPlay,
    trackProgress,
    handleTimeUpdateTrackers,
    resetProgressTracking,
    trackStart,
    trackComplete,
  };
};

export default useVideoTracker;
