import { ECARD_TYPES } from "enums";
import localStorage, { ECARD, TEMPLATE_GROUP, IN_FLOW, SOURCE_PHOTO, MORPHABLE_PACK_GROUP } from "utils/localStorage";

export const getInFlow = () => localStorage.getItem(IN_FLOW);

export const getEcard = () => localStorage.getItem(ECARD);
export const getTemplateGroup = () => localStorage.getItem(TEMPLATE_GROUP);
export const getSourcePhoto = () => localStorage.getItem(SOURCE_PHOTO);
export const getMorphablePackGroup = () => localStorage.getItem(MORPHABLE_PACK_GROUP);

export const isMake = () => getEcard()?.type === ECARD_TYPES.MAKE;
export const isInvite = () => getEcard()?.type === ECARD_TYPES.INVITE;
export const isMorphable = () => getEcard()?.type === ECARD_TYPES.MORPHABLE;
