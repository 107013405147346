import { trackSelectContent } from "tracking/google/analytics";

const useTemplateGroupTracking = (templateGroup) => {
  const trackClick = () => {
    trackSelectContent(templateGroup);
  };

  return {
    trackClick,
  };
};

export default useTemplateGroupTracking;
