import R from "ramda";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import PeopleRepository from "repositories/PeopleRepository";
import { trackPersonCreationSuccess, trackPersonUpdateSuccess } from "tracking/google/analytics";
import { deserialize, mergeById } from "utils/storeUtils";
import { getPersonToUpdate } from "utils/peopleUtils";

const initialState = {
  people: [],
  meta: null,
  peopleToUpdate: getPersonToUpdate(),
  peopleToCast: [],
};

const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    loadPeopleSuccess(state, { payload }) {
      state.people = deserialize(payload);
      state.meta = R.propOr(null, "meta", payload);
    },
    loadAdditionalPeopleSuccess(state, { payload }) {
      state.people = R.union(state.people, deserialize(payload));
      state.meta = R.propOr(null, "meta", payload);
    },
    createPersonSuccess(state, { payload }) {
      state.people = R.concat(state.people, [deserialize(payload)]);
    },
    updatePersonSuccess(state, { payload }) {
      const personUpdated = deserialize(payload);
      let tempPersons = mergeById(state.people, [], personUpdated.id, personUpdated);
      const relationships = ["me", "partner", "mother", "father", "family", "friend", "pet"];
      const indexRelation = R.indexOf(personUpdated.relation, relationships);
      const newRelationsToCheck = R.slice(0, indexRelation, relationships);
      let indexPersonToUpdate;
      let personToUpdate;
      tempPersons.forEach((person, i) => {
        if (person.id === personUpdated.id) {
          indexPersonToUpdate = i;
          personToUpdate = person;
        }
      });
      tempPersons.splice(indexPersonToUpdate, 1);
      let lastIndexInPersons = -1;
      tempPersons.forEach((person, i) => {
        if (R.includes(person.relation, newRelationsToCheck)) lastIndexInPersons = i;
      });
      tempPersons = R.insert(lastIndexInPersons + 1, personToUpdate, tempPersons);
      state.people = tempPersons;
    },
    resetPeople(state) {
      state.people = [];
      state.meta = null;
    },
    addPersonToUpdate(state, { payload }) {
      const { person, face } = payload;
      const personToUpdate = {
        ...person,
        newDefaultHeadId: face.id,
        defaultHead: { ...face, default: true, type: "default-heads" },
      };
      const personIndex = R.findIndex(R.propEq("id", personToUpdate.id))(state.peopleToUpdate);
      if (personIndex === -1) {
        state.peopleToUpdate.push(personToUpdate);
      } else {
        state.peopleToUpdate = R.update(personIndex, personToUpdate, state.peopleToUpdate);
      }
    },
    resetPeopleToUpdate(state) {
      state.peopleToUpdate = [];
    },
    decreaseHeadToPerson(state, { payload }) {
      const { personId } = payload;
      state.people = state.people.map((person) => {
        let personsHeads = person.totalHeads;
        if (person.id === personId) personsHeads -= 1;
        return { ...person, totalHeads: personsHeads };
      });
    },
    deletePersonSuccess(state, { payload }) {
      const { personId } = payload;
      state.people = state.people.filter((person) => {
        return personId !== person.id;
      });
    },
    loadPeopleToCast(state, { payload }) {
      state.peopleToCast = payload;
    },
    resetPeopleToCast(state) {
      state.peopleToCast = [];
    },
  },
});

export const { actions } = peopleSlice;
export default peopleSlice.reducer;

export const usePeopleActions = () => {
  const dispatch = useDispatch();

  const loadPeople = (params) => {
    return PeopleRepository.index(params).then(({ data }) => {
      dispatch(peopleSlice.actions.loadPeopleSuccess(data));
      return data;
    });
  };

  const loadAdditionalPeople = (params) => {
    return PeopleRepository.index(params).then(({ data }) => {
      dispatch(peopleSlice.actions.loadAdditionalPeopleSuccess(data));
      return data;
    });
  };

  const createPerson = (params) => {
    return PeopleRepository.create(params).then(({ data }) => {
      const person = deserialize(data);
      trackPersonCreationSuccess(person);
      dispatch(peopleSlice.actions.createPersonSuccess(data));
      dispatch(peopleSlice.actions.updatePersonSuccess(data));
      return person;
    });
  };

  const deletePersonStorage = (personId) => {
    dispatch(peopleSlice.actions.deletePersonSuccess({ personId }));
  };
  const deletePerson = (personId, params) => {
    return PeopleRepository.delete(personId, params).then(() => {
      deletePersonStorage(personId);
    });
  };

  const updatePerson = (personId, params) => {
    return PeopleRepository.update(personId, params).then(({ data }) => {
      const person = deserialize(data);
      trackPersonUpdateSuccess(person);
      dispatch(peopleSlice.actions.updatePersonSuccess(data));
    });
  };

  const resetPeople = () => {
    dispatch(peopleSlice.actions.resetPeople());
  };

  const addPersonToUpdate = (person, face) => {
    dispatch(peopleSlice.actions.addPersonToUpdate({ person, face }));
  };

  const resetPeopleToUpdate = () => {
    dispatch(peopleSlice.actions.resetPeopleToUpdate());
  };

  const decreaseHeadToPerson = (personId) => {
    dispatch(peopleSlice.actions.decreaseHeadToPerson({ personId }));
  };

  const loadPeopleToCast = (persons) => {
    dispatch(peopleSlice.actions.loadPeopleToCast(persons));
  };

  const resetPeopleToCast = () => {
    dispatch(peopleSlice.actions.resetPeopleToCast());
  };

  return {
    loadPeople,
    loadAdditionalPeople,
    createPerson,
    deletePerson,
    deletePersonStorage,
    updatePerson,
    resetPeople,
    addPersonToUpdate,
    resetPeopleToUpdate,
    decreaseHeadToPerson,
    loadPeopleToCast,
    resetPeopleToCast,
  };
};
