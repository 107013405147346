import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

import { getImageUrl } from "utils/imageHandlerUtils";

const ResponsiveImage = ({ src, alt, width, widthMobile, maxScale, watermark, ...props }) => {
  const theme = useTheme();
  const { mobile: mobileBreakpoint } = theme.breakpoints.values;

  const createSrcSet = (newWidth) => {
    let srcSet = "";

    for (let i = 1; i <= maxScale; i += 1) {
      const newSize = i * newWidth;
      srcSet += `${getImageUrl(src, { newWidth: newSize, watermark })} ${newSize}w,`;
    }

    return srcSet;
  };

  const srcSet = `${createSrcSet(widthMobile)} ${createSrcSet(width)}`;
  const sizes = `(max-width: ${mobileBreakpoint}px) ${widthMobile}px, ${width}px`;

  return (
    <img
      src={getImageUrl(src, { newWidth: width, watermark })}
      srcSet={srcSet}
      sizes={sizes}
      alt={alt}
      width={width}
      {...props}
    />
  );
};

ResponsiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  widthMobile: PropTypes.number.isRequired,
  maxScale: PropTypes.number,
  watermark: PropTypes.bool,
};

ResponsiveImage.defaultProps = {
  maxScale: 3,
  watermark: false,
};

export default ResponsiveImage;
