export const isWebGLHardwareAccelerated = () => {
  const canvas = document.createElement("canvas");

  const gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl") || canvas.getContext("webgl2");

  if (!gl) return false;

  const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");

  if (debugInfo) {
    const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
    const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);

    if (
      renderer.toLowerCase().includes("opengl") ||
      renderer.toLowerCase().includes("metal") ||
      vendor.toLowerCase().includes("intel") ||
      vendor.toLowerCase().includes("amd") ||
      vendor.toLowerCase().includes("nvidia")
    ) {
      return true;
    }
  }

  const startTime = performance.now();
  const testSize = 1024;
  const testPixels = new Uint8Array(testSize * testSize * 4);
  gl.readPixels(0, 0, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, testPixels);
  const renderTime = performance.now() - startTime;

  return renderTime < 50;
};

export const noWebGL = () => !isWebGLHardwareAccelerated();
