import { useSelector } from "react-redux";

import { useMorphedPackActions } from "store/MorphedPackSlice";
import { appRoutes } from "routes";
import { jjLogger } from "utils/logUtils";
import MorphedPackPresenter from "presenters/MorphedPackPresenter";
import MorphablePackGroupPresenter from "presenters/MorphablePackGroupPresenter";
import { deserializeErrors } from "utils/storeUtils";
import { completedPackEvent } from "tracking/jibjab/faceswap";
import { trackPackGenerated } from "tracking/google/analytics";
import usePolling from "hooks/usePolling";
import useRouter from "hooks/useRouter";
import useLastEcard from "hooks/useLastEcard";
import useUsers from "hooks/useUsers";
import useAlgoliaEvents from "hooks/useAlgoliaEvents";
import useSYAIErrorHandler from "hooks/useSYAIErrorHandler";

const useMorphedPack = () => {
  const {
    loadMorphedPack,
    createMorphedPack,
    generatePreviewPhoto,
    generateStandardPhotos,
    getStatus,
    loadMorphedPacks,
    loadMoreMorphedPacks,
  } = useMorphedPackActions();
  const { morphedPack, morphedPacks, meta } = useSelector((state) => state.MorphedPackSlice);
  const { replace } = useRouter();
  const { stopPolling } = usePolling();
  const { clearLastEcard } = useLastEcard();
  const { currentUser } = useUsers();
  const { handleStatusError, handleMorphedPackError, handlePhotoError } = useSYAIErrorHandler();
  const { trackPackPreviewAfterSearch } = useAlgoliaEvents();

  const pollStatus = async (morphedPackSlug, expectedStatus, isPreview = false) => {
    try {
      const packSlug = morphedPackSlug || MorphedPackPresenter.id(morphedPack);
      const { data } = await getStatus(packSlug);
      const { status } = data;

      if (status === expectedStatus) {
        stopPolling();

        if (isPreview) {
          trackPackPreviewAfterSearch(MorphablePackGroupPresenter.algoliaId(morphedPack?.morphablePackGroup));
          replace(appRoutes.syaiPackPreviewPath(packSlug));
        } else {
          trackPackGenerated(morphedPack?.morphablePackGroup?.id);
          completedPackEvent({ user: currentUser, morphedPack });
          replace(appRoutes.syaiPackViewPath(packSlug, "0"));
          clearLastEcard();
        }
      }
    } catch (error) {
      if (error?.response?.data?.error) {
        const { code, status, message } = error.response.data.error;
        handleStatusError(code);
        jjLogger.logError(
          `useMorphedPack.js: pollStatus() Error polling for pack status: ${message} (code: ${code}, status: ${status})`,
        );
      } else {
        jjLogger.logError(`useMorphedPack.js: pollStatus() Error polling for pack status: ${error}`);
      }
      stopPolling();
    }
  };

  const saveMorphedPack = async (sourceId, type, packSlug, packGroupSlug) => {
    const params = {
      data: {
        type: "morphed-packs",
        attributes: {
          "source-photo": sourceId,
          "photo-type": type,
          "photo-pack-slug": packSlug,
        },
      },
    };

    try {
      return await createMorphedPack(params);
    } catch (error) {
      const { response } = error;
      if (response?.data?.errors) {
        const [firstError] = response.data.errors;
        const errorToHandle = firstError?.source?.pointer?.split("/").pop();

        handleMorphedPackError(errorToHandle, packGroupSlug);
      }

      const deserializedErrors = deserializeErrors(error);
      jjLogger.logError(`useMorphedPack.js: saveMorphedPack() Error saving morphed pack: ${deserializedErrors}`);
      throw new Error(deserializedErrors);
    }
  };

  const generatePreview = async (morphedPackSlug, morphedPackGroupSlug) => {
    try {
      await generatePreviewPhoto(morphedPackSlug);
    } catch (error) {
      jjLogger.logError(`useMorphedPack.js: generatePreview() Error generating preview photos: ${error}`);
      handlePhotoError(morphedPackGroupSlug);
      throw error;
    }
  };

  const generatePhotos = async (morphedPackSlug) => {
    try {
      await generateStandardPhotos(morphedPackSlug);
    } catch (error) {
      jjLogger.logError(`useMorphedPack.js: generatePhotos() Error generating standard photos: ${error}`);
    }
  };

  return {
    loadMorphedPack,
    saveMorphedPack,
    morphedPack,
    generateStandardPhotos,
    getStatus,
    pollStatus,
    generatePreview,
    generatePhotos,
    loadMorphedPacks,
    morphedPacks,
    loadMoreMorphedPacks,
    meta,
  };
};

export default useMorphedPack;
