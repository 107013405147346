import { isNil } from "ramda";

import MorphedPackPresenter from "presenters/MorphedPackPresenter";
import MakePresenter from "presenters/MakePresenter";
import InvitePresenter from "presenters/InvitePresenter";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import UserPresenter from "presenters/UserPresenter";
import { appRoutes } from "routes";
import { jjLogger } from "utils/logUtils";
import useUtmsParams from "hooks/useUtmsParams";
import useLastEcard from "hooks/useLastEcard";
import useFaces from "hooks/useFaces";
import useRouter from "hooks/useRouter";

const useRedirectAfterAuth = () => {
  const { utmsParams } = useUtmsParams();
  const { templateGroup, ecard, isInvite, isMorphable, inFlow } = useLastEcard();
  const { loadFaces } = useFaces();
  const { push, location, replace } = useRouter();

  const buildInvitePreviewPath = () => {
    const id = InvitePresenter.id(ecard);

    return appRoutes.inviteMakePreviewPath(id);
  };

  const buildMakePreviewPath = () => {
    const makeId = MakePresenter.id(ecard);
    const tgId = TemplateGroupPresenter.id(templateGroup);

    return appRoutes.makeViewPath(tgId, makeId);
  };

  const buildMorphedPackPreviewPath = () => {
    const morphableId = MorphedPackPresenter.id(ecard);
    return appRoutes.syaiPackPreviewPath(morphableId);
  };

  const buildPreviewPath = () => {
    if (isInvite) return buildInvitePreviewPath();
    if (isMorphable) return buildMorphedPackPreviewPath();

    return buildMakePreviewPath();
  };

  const buildRedirectPath = (user) => {
    const needsPayment = UserPresenter.notPaid(user) && TemplateGroupPresenter.premium(templateGroup);
    const previewPath = buildPreviewPath();
    const path = needsPayment ? appRoutes.paymentPath() : previewPath;

    jjLogger.logDebug(`useLastEcard.js: buildRedirectPath(): path: ${path}`);
    return { pathname: path, state: { referrer: "inFlow" }, search: utmsParams };
  };

  const redirectAfterLogin = async (user) => {
    const referrer = location.state?.referrer;

    await loadFaces();

    if (inFlow && ecard) return push(buildRedirectPath(user));
    if (!isNil(referrer)) return push(referrer);

    return push({ pathname: appRoutes.rootPath(), search: utmsParams });
  };

  const redirectAfterSignUp = (user) => {
    jjLogger.logDebug("useRedirectAfterAuth.js: redirectAfterSignUp()");

    if (inFlow && ecard) return replace(buildRedirectPath(user));

    return replace({ pathname: appRoutes.rootPath(), search: utmsParams });
  };

  return {
    buildRedirectPath,
    redirectAfterLogin,
    redirectAfterSignUp,
  };
};

export default useRedirectAfterAuth;
