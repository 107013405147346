import palette from "layouts/ThemeProvider/palette";

const HEX_TRANSPARENCY = "20";

const buttonDefault = {
  height: 40,
  fontSize: 14,
  fontWeight: 600,
  padding: "8px 32px",
  borderWidth: 2,
  borderRadius: 20,
  textTransform: "none",
  "&:disabled": {
    background: palette.disabled.main,
    color: palette.disabled.contrastText,
  },
};

const outlinedDefaultDisable = {
  "&:disabled": {
    borderColor: palette.disabled.contrastText,
    background: "white",
    color: palette.disabled.contrastText,
  },
};

const fullDefaultOutlined = {
  ...buttonDefault,
  ...outlinedDefaultDisable,
};

const outlinedDefault = (color) => {
  return {
    borderColor: color,
    color,
    "&:hover": {
      "@media (hover: hover)": {
        background: `${color}${HEX_TRANSPARENCY}`,
        borderColor: color,
        borderWidth: 2,
      },
    },
    "&:active": {
      background: color,
      color: "white",
    },
  };
};

const containedDefault = (color) => {
  return {
    background: color,
    "&:active": {
      background: palette.primary.light,
    },
    "&:hover": {
      "@media (hover: hover)": {
        color: "white",
      },
    },
  };
};

const textDefault = (color) => {
  return {
    borderRadius: 18,
    "&:hover": {
      "@media (hover: hover)": {
        background: `${color}${HEX_TRANSPARENCY}`,
      },
    },
  };
};

const linkDefault = {
  padding: 0,
  textTransform: "initial",
  fontSize: 16,
  color: palette.neutral.light,
  "&:hover": {
    color: palette.primary.main,
    backgroundColor: "transparent",
  },
};

const connectDefault = (color) => {
  return {
    ...outlinedDefault(color),
    borderWidth: 1,
    color: palette.neutral.dark,
    paddingLeft: "12px",
    "&:hover": {
      "@media (hover: hover)": {
        background: `${color}${HEX_TRANSPARENCY}`,
        borderWidth: 1,
      },
    },
    "&:active": {
      background: `${color}${HEX_TRANSPARENCY}`,
      color: palette.neutral.dark,
    },
  };
};

export default {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
      disableTouchRipple: true,
      disableElevation: true,
    },
    styleOverrides: {
      outlined: fullDefaultOutlined,
      outlinedPrimary: outlinedDefault(palette.primary.main),
      outlinedSecondary: outlinedDefault(palette.secondary.main),
      outlinedDelete: outlinedDefault(palette.delete.main),
      outlinedConnect: connectDefault(palette.connect.main),
      contained: buttonDefault,
      containedPrimary: containedDefault(palette.primary.main),
      textPrimary: textDefault(palette.text.main),
      textSecondary: textDefault(palette.secondary.main),
      textDelete: textDefault(palette.delete.main),
      textWarning: textDefault(palette.warning.main),
      textSuccess: textDefault(palette.success.main),
      textPrimaryLight: textDefault(palette.primary.light),
      textSecondaryLight: textDefault(palette.secondary.light),
      textDark: textDefault(palette.dark.main),
      link: linkDefault,
      sizeXsmall: {
        height: 44,
        width: 116,
        fontSize: 14,
        fontWeight: 600,
        padding: 0,
      },
      sizeLarge: {
        height: 60,
        borderRadius: 30,
        borderWidth: 2,
        fontSize: 20,
        padding: "0px 34px",
      },
    },
  },
};
