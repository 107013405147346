import R from "ramda";
import { useCookie } from "react-recipes";
import { useSelector } from "react-redux";

import { useOffersActions } from "store/OffersSlice";
import useRouter from "hooks/useRouter";
import useOfferGroups from "hooks/useOfferGroups";
import { jjLogger } from "utils/logUtils";

const OFFER_COOKIE_EXPIRE_DAYS = 14;

const useOffers = () => {
  const { loadOffers, switchCurrentOffer, clearCurrentOffer } = useOffersActions();
  const {
    currentOffer: currentOfferFromOfferGroups,
    loadOfferGroups,
    clearCurrentOffer: clearCurrentGroupOffer,
  } = useOfferGroups();
  const { currentOffer, offers } = useSelector((state) => state.OffersSlice);

  const {
    query: { offer: queryOffer = null },
  } = useRouter();

  const [currentOfferId, setCurrentOfferId] = useCookie("offer");

  const setCurrentOffer = () => {
    if (!R.isNil(queryOffer)) {
      setCurrentOfferId(queryOffer, OFFER_COOKIE_EXPIRE_DAYS);
    }
  };

  const fetchOffers = async (uriSlugs, isBaselineOffer) => {
    try {
      await loadOffers(uriSlugs);
    } catch (error) {
      jjLogger.logError(
        `useOffers.js - fetchOffers() - Error: Could not load offer "${uriSlugs}". ${
          error?.message ? error.message : error
        }`,
      );
      await loadOfferGroups(isBaselineOffer);
    }
  };

  const loadCurrentOffer = async (isBaselineOffer) => {
    R.isNil(currentOfferId)
      ? await loadOfferGroups(isBaselineOffer)
      : await fetchOffers(currentOfferId, isBaselineOffer);
  };

  const resetCurrentOffer = () => {
    clearCurrentOffer();
    clearCurrentGroupOffer();
  };

  return {
    currentOffer: currentOffer || currentOfferFromOfferGroups,
    offers,
    fetchOffers,
    switchCurrentOffer,
    setCurrentOffer,
    loadCurrentOffer,
    resetCurrentOffer,
  };
};

export default useOffers;
