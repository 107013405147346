/* global DetectUserAgent */
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "ramda";

import UserPresenter from "presenters/UserPresenter";
import OfferPresenter from "presenters/OfferPresenter";
import { getTimeZone, getCurrentDateInUTC } from "utils/dateTimeUtils";
import { jjLogger } from "utils/logUtils";
import { JIBJAB_TRACKING_ID } from "utils/localStorage";
import { METRICS } from "enums/tracking";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import { LOGIN_SIGNUP_METHODS } from "enums";
import { getContentTypeInFlow } from "tracking/shared";
import { isMethodValid } from "validators/tracking";

export const METRIC = METRICS.PREANON;

export const getTrackingId = () => localStorage.getItem(JIBJAB_TRACKING_ID);
export const setTrackingId = (value) => localStorage.setItem(JIBJAB_TRACKING_ID, value);

export const setupTrackingId = () => {
  if (DetectUserAgent.isPrerender()) return;

  const trackingId = getTrackingId();

  if (trackingId) {
    jjLogger.log("tracking.js: trackingId already set");
    return;
  }

  jjLogger.log("tracking.js: setting trackingId");
  localStorage.setItem(JIBJAB_TRACKING_ID, uuidv4());
};

export const eventData = (event, user, extraData = {}) => {
  const trackingId = getTrackingId();
  const referrerUrl = sessionStorage.getItem("referrerUrl");

  if (!trackingId) {
    jjLogger.logError(`trackEvent: - Tracking ID is not set for ${event} event`);
    return {};
  }

  if (!referrerUrl) jjLogger.logError(`trackEvent: - Referrer URL is not set for ${event} event`);

  return {
    event,
    tracking_id: trackingId,
    event_timestamp: getCurrentDateInUTC(),
    metric: METRIC,
    referrer_url: referrerUrl === "direct" ? "" : referrerUrl,
    current_url: `${window.location.origin}${window.location.pathname}`,
    user_agent: navigator.userAgent,
    env: process.env.DEPLOY_ENV,
    user_id: UserPresenter.id(user),
    user_status: UserPresenter.analyticsStatus(user),
    client_timezone: getTimeZone(),
    query_string: window.location.search,
    ...extraData,
  };
};

const sendBeacon = (payload) => {
  const analyticsUrl = process.env.JIBJAB_ANALYTICS_URL;
  if (!analyticsUrl || DetectUserAgent.isPrerender() || isEmpty(payload)) return;

  navigator.sendBeacon(analyticsUrl, JSON.stringify(payload));
};

export const trackEvent = (eventName, user, extraData = {}) => {
  const data = eventData(eventName, user, extraData);
  sendBeacon(data);
};

export const pageViewEvent = (user) => {
  trackEvent("page_view", user);
};

export const signUpEvent = (user, method, templateGroup) => {
  const extraData = {
    method,
    content_type: TemplateGroupPresenter.templateGroupType(templateGroup),
  };

  trackEvent("sign_up", user, extraData);
};

export const viewSignUpEvent = () => {
  trackEvent("view_sign_up", {}, { content_type: getContentTypeInFlow() });
};

export const viewPaygateEvent = (user) => {
  trackEvent("view_paygate", user, { content_type: getContentTypeInFlow() });
};

export const clickSignUpEvent = (method) => {
  const validMethods = Object.values(LOGIN_SIGNUP_METHODS).map((m) => `signup-${m}`);

  if (!validMethods.includes(method)) {
    jjLogger.logError(`clickSignUpEvent: - Method ${method} is not a valid method`);
    return;
  }

  trackEvent("click_sign_up", {}, { method, content_type: getContentTypeInFlow() });
};

export const purchaseEvent = (user, offer) => {
  const subscriptionId = UserPresenter.activeSubscription(user).id;
  const duration = `${OfferPresenter.duration(offer)} ${OfferPresenter.durationUnit(offer)}`;
  const price = OfferPresenter.priceRounded(offer);

  trackEvent("purchase", user, {
    subscription_id: subscriptionId,
    duration,
    price,
    content_type: getContentTypeInFlow(),
  });
};

export const loginEvent = (user, method, templateGroup) => {
  const extraData = {
    method,
    content_type: TemplateGroupPresenter.templateGroupType(templateGroup),
  };

  trackEvent("login", user, extraData);
};

export const draftContentEvent = (user) => {
  if (!UserPresenter.isAnonymous(user)) return;

  trackEvent("draft_content", user);
};

export const viewLoginEvent = () => {
  trackEvent("view_login", {}, { content_type: getContentTypeInFlow() });
};

export const clickLoginEvent = (method) => {
  if (!isMethodValid(method)) {
    jjLogger.logError(`clickLoginEvent: - Invalid method: ${method}`);
    return;
  }

  trackEvent("click_login", {}, { method, content_type: getContentTypeInFlow() });
};

export const experimentViewedEvent = (experimentId, variationId) => {
  if (!experimentId || !variationId) {
    jjLogger.logDebug(`experimentViewedEvent: - ${!experimentId ? "Experiment" : "Variation"} ID is not set`);
  }

  const trackingId = getTrackingId();
  if (!trackingId) {
    jjLogger.logError("trackEvent: - Tracking ID is not set for viewed_experiment event");
    return;
  }

  const payload = {
    tracking_id: trackingId,
    experiment_id: experimentId ?? null,
    variation_id: variationId ?? null,
    env: process.env.DEPLOY_ENV,
    metric: "viewed_experiment",
  };

  sendBeacon(payload);
};
