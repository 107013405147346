import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import R from "ramda";

import { FACE_PAGE_FLOWS } from "enums";
import FaceRepository from "repositories/FaceRepository";
import GoogleApiRepository from "repositories/GoogleApiRepository";
import useActions from "hooks/useActions";
import { removeById, deserialize, mergeById } from "utils/storeUtils";
import { newRelic, jjLogger } from "utils/logUtils";
import { trackFaceDetected } from "tracking/google/analytics";
import { sortByKeyDate } from "utils/dateTimeUtils";

const MAX_HEAD_SIZE = 8;

const initialState = {
  faces: [],
  meta: null,
  modalOpened: false,
  isCasting: false,
  relationToCreate: "",
  photo: null,
  newFaces: null,
  transforms: {},
  addedFacesIds: [],
  mode: FACE_PAGE_FLOWS.NO_PHOTO,
  previousMode: "",
  personToAddHead: null,
  facesToAddPerson: [],
  faceToAddJaw: null,
};

const facesSlice = createSlice({
  name: "faces",
  initialState,
  reducers: {
    loadFacesSuccess(state, { payload }) {
      state.faces = deserialize(payload);
      state.meta = R.propOr(null, "meta", payload);
    },
    loadAdditionalFacesSuccess(state, { payload }) {
      const faces = R.unionWith(R.eqBy(R.prop("id")), state.faces, deserialize(payload));
      state.faces = sortByKeyDate("createdAt", faces, "DSC");
      state.meta = R.propOr(null, "meta", payload);
    },
    convertToOrphanFaces(state, { payload }) {
      const { id } = payload;
      state.faces = state.faces.map((face) => {
        if (face?.id === id) {
          return {
            ...face,
            person: null,
          };
        }
        return face;
      });
    },
    resetFaces(state) {
      state.faces = [];
      state.meta = null;
    },
    openModal(state) {
      state.modalOpened = true;
    },
    setIsCasting(state, { payload }) {
      state.isCasting = payload;
    },
    closeModal(state) {
      state.modalOpened = false;
    },
    setRelationToCreate(state, { payload }) {
      const relationToCreate = payload;
      state.relationToCreate = R.isEmpty(relationToCreate) ? "" : relationToCreate;
    },
    clearRelationToCreate(state) {
      state.relationToCreate = "";
    },
    setPhotoSuccess(state, { payload }) {
      state.photo = payload.photo;
    },
    clearPhoto(state) {
      state.photo = null;
    },
    detectFacesSuccess(state, { payload }) {
      const response = R.head(payload.responses);
      const { faceAnnotations } = response;

      if (
        R.isNil(faceAnnotations) ||
        R.isNil(faceAnnotations[0].fdBoundingPoly) ||
        R.isEmpty(faceAnnotations[0].fdBoundingPoly)
      ) {
        state.newFaces = [];
      } else {
        const hasChinData = R.any(R.propEq("type", "CHIN_GNATHION"));
        const filterResponse = R.filter(R.compose(hasChinData, R.prop("landmarks")), faceAnnotations);

        if (faceAnnotations.length !== filterResponse.length) {
          newRelic("Auto Headcut Error: Missing chinGnathion position data");
          jjLogger.log("Auto Headcut Error: Missing chinGnathion position data");
        }

        const newFaces = R.isEmpty(filterResponse) ? [] : filterResponse.slice(0, MAX_HEAD_SIZE);

        trackFaceDetected(newFaces.length);

        state.newFaces = newFaces;
      }
    },
    setEmptyArrayToNewFaces(state) {
      state.newFaces = [];
    },
    clearFacesSuccess(state) {
      state.newFaces = null;
    },
    saveFaceSuccess(state, { payload }) {
      const { newFace, person } = payload;
      newFace.person = person;

      if (state?.meta?.totalCount) {
        const newMeta = { ...state.meta, totalCount: state.meta.totalCount + 1 };
        state.meta = newMeta;
      } else {
        state.meta = { totalCount: 1 };
      }
      state.faces = R.union(state.faces, [newFace]);
      state.addedFacesIds = [...state.addedFacesIds, newFace.id];
      state.transforms = {};
    },
    deleteFaceSuccess(state, { payload }) {
      const newFaces = removeById(state.faces, [], payload.slug);
      state.faces = newFaces;

      if (state?.meta?.totalCount) {
        const newMeta = { ...state.meta, totalCount: state.meta.totalCount - 1 };
        state.meta = newMeta;
      }
    },
    resetAdded(state) {
      state.addedFacesIds = [];
    },
    saveTransform(state, { payload }) {
      const { id, transforms } = payload;
      state.transforms[id] = transforms;
    },
    resetTransforms(state) {
      state.transforms = {};
    },
    setMode(state, { payload }) {
      state.mode = payload;
    },
    setPreviousMode(state, { payload }) {
      state.previousMode = payload;
    },
    setPersonToAddHead(state, { payload }) {
      state.personToAddHead = payload;
    },
    clearPersonToAddHead(state) {
      state.personToAddHead = null;
    },
    setFacesToAddPerson(state, { payload }) {
      state.facesToAddPerson = payload;
    },
    clearFacesToAddPerson(state) {
      state.facesToAddPerson = [];
    },
    setFaceToAddJaw(state, { payload }) {
      state.faceToAddJaw = payload;
    },
    clearFaceToAddJaw(state) {
      state.faceToAddJaw = null;
    },
    storePersonByFaceId(state, { payload: { faceId, person } }) {
      state.faces = mergeById(state.faces, [], faceId, { person });
    },
  },
});

export const { actions } = facesSlice;
export default facesSlice.reducer;

export const useFacesActions = () => {
  const dispatch = useDispatch();
  const { openModal, closeModal } = useActions(actions);

  const loadFaces = (params) => {
    return FaceRepository.index(params)
      .then(({ data }) => {
        dispatch(facesSlice.actions.loadFacesSuccess(data));
        return data;
      })
      .catch(() => {});
  };

  const clearPhoto = () => {
    dispatch(facesSlice.actions.clearPhoto());
  };

  const setPhoto = (photo) => {
    dispatch(facesSlice.actions.setPhotoSuccess({ photo }));
  };

  const setIsCasting = (status) => {
    dispatch(facesSlice.actions.setIsCasting(status));
  };

  const loadAdditionalFaces = (params) => {
    return FaceRepository.index(params).then(({ data }) => {
      dispatch(facesSlice.actions.loadAdditionalFacesSuccess(data));
      return data;
    });
  };

  const createFace = (params, person) => {
    return FaceRepository.create(params).then(({ data }) => {
      const newFace = deserialize(data);
      dispatch(facesSlice.actions.saveFaceSuccess({ newFace, person }));
      return newFace;
    });
  };

  const updateFace = (slug, params) => {
    return FaceRepository.update(slug, params).then(({ data }) => {
      const newFace = deserialize(data);
      return newFace;
    });
  };

  const resetAdded = () => dispatch(facesSlice.actions.resetAdded());

  const deleteFace = (slug) => {
    return FaceRepository.delete(slug).then(() => {
      dispatch(facesSlice.actions.deleteFaceSuccess({ slug }));
      return slug;
    });
  };

  const detectFaces = (key, params) => {
    jjLogger.logDebug("FacesSlice.js: Start detect faces");
    jjLogger.logDebug("FacesSlice.js: params");
    jjLogger.logDebug(JSON.stringify(params));
    return GoogleApiRepository.detectFaces(key, params)
      .then(({ data }) => {
        dispatch(facesSlice.actions.detectFacesSuccess(data));
        jjLogger.logDebug("FacesSlice.js: data");
        jjLogger.logDebug(JSON.stringify(data));
        jjLogger.logDebug("FacesSlice.js: Finish detect faces");
        return data;
      })
      .catch((error) => {
        jjLogger.logDebug("FacesSlice.js: Fail detect faces with the following error:");
        jjLogger.logDebug(JSON.stringify(error));
        jjLogger.logDebug("FacesSlice.js: Send user to manual head cut");
        dispatch(facesSlice.actions.setEmptyArrayToNewFaces());
      });
  };

  const saveTransform = (id, transforms) => {
    dispatch(facesSlice.actions.saveTransform({ id, transforms }));
  };

  const resetTransforms = () => {
    dispatch(facesSlice.actions.resetTransforms());
  };

  const clearFoundFaces = () => {
    dispatch(facesSlice.actions.clearFacesSuccess());
  };

  const setMode = (params) => {
    dispatch(facesSlice.actions.setMode(params));
  };

  const setPreviousMode = (params) => {
    dispatch(facesSlice.actions.setPreviousMode(params));
  };

  const resetFaces = () => {
    dispatch(facesSlice.actions.resetFaces());
  };

  const setRelationToCreate = (params) => {
    dispatch(facesSlice.actions.setRelationToCreate(params));
  };

  const clearRelationToCreate = () => {
    dispatch(facesSlice.actions.clearRelationToCreate());
  };

  const setPersonToAddHead = (params) => {
    dispatch(facesSlice.actions.setPersonToAddHead(params));
  };

  const clearPersonToAddHead = () => {
    dispatch(facesSlice.actions.clearPersonToAddHead());
  };

  const setFacesToAddPerson = (params) => {
    dispatch(facesSlice.actions.setFacesToAddPerson(params));
  };

  const clearFacesToAddPerson = () => {
    dispatch(facesSlice.actions.clearFacesToAddPerson());
  };

  const setFaceToAddJaw = (params) => {
    dispatch(facesSlice.actions.setFaceToAddJaw(params));
  };

  const clearFaceToAddJaw = () => {
    dispatch(facesSlice.actions.setFaceToAddJaw());
  };

  const storePersonByFaceId = (faceId, person) => {
    dispatch(facesSlice.actions.storePersonByFaceId({ faceId, person }));
  };
  const convertToOrphanFace = (personFace) => {
    dispatch(facesSlice.actions.convertToOrphanFaces(personFace));
  };

  return {
    loadFaces,
    loadAdditionalFaces,
    deleteFace,
    openModal,
    closeModal,
    setPhoto,
    clearPhoto,
    detectFaces,
    createFace,
    updateFace,
    resetAdded,
    saveTransform,
    resetTransforms,
    clearFoundFaces,
    setMode,
    setPreviousMode,
    resetFaces,
    setRelationToCreate,
    clearRelationToCreate,
    setPersonToAddHead,
    clearPersonToAddHead,
    setFacesToAddPerson,
    clearFacesToAddPerson,
    setFaceToAddJaw,
    clearFaceToAddJaw,
    setIsCasting,
    storePersonByFaceId,
    convertToOrphanFace,
  };
};
