const SKIN_COLORS = {
  light: "Light",
  medium: "Medium",
  dark: "Dark",
};

Object.freeze(SKIN_COLORS);

const PACK_STATUSES = {
  DRAFT: "draft",
  QUEUED_PREVIEW: "queued-preview",
  GENERATING_PREVIEW: "generating-preview",
  PREVIEW: "preview",
  QUEUED_STANDARD: "queued-standard",
  GENERATING_STANDARD: "generating-standard",
  LIVE: "live",
  DELETED: "deleted",
};

Object.freeze(PACK_STATUSES);

const PACK_ERROR_CODES = {
  INVALID_SOURCE_PHOTO: 1,
  INVALID_TARGET_PHOTO: 2,
  INTERNAL_API_FAILURE: 3,
};

Object.freeze(PACK_ERROR_CODES);

const PACK_ERROR_POINTERS = {
  SOURCE_PHOTO: "source-photo",
  PHOTO_TYPE: "photo-type",
  PHOTO_PACK_SLUG: "photo-pack-slug",
};

Object.freeze(PACK_ERROR_POINTERS);

const PACK_ERROR_MESSAGES = {
  INVALID_SOURCE_PHOTO: "No faces found, please upload a new one",
  INVALID_TARGET_PHOTO: "Please try a different pack",
  INTERNAL_API_FAILURE: "Comeback later, the pack will be in your library soon",
  SOURCE_PHOTO_FAILD_LOAD: "There was an error loading the photo, please upload again",
};

Object.freeze(PACK_ERROR_MESSAGES);

const PHOTO_TYPES = {
  HEAD: "head",
  SOURCE_PHOTO: "source_photo",
};

Object.freeze(PHOTO_TYPES);

const PHOTO_ACTIONS = {
  DOWNLOAD: "download",
  SHARE: "share",
};

Object.freeze(PHOTO_ACTIONS);

export {
  SKIN_COLORS,
  PACK_STATUSES,
  PACK_ERROR_CODES,
  PACK_ERROR_MESSAGES,
  PHOTO_TYPES,
  PACK_ERROR_POINTERS,
  PHOTO_ACTIONS,
};
