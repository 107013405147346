const OFFER_VARIANTS = {
  BASELINE: "baseline",
  PER_MONTH_PRICE: "perMonthPrice",
  FULL_PRICE: "fullPrice",
};

const FEATURE_FLAGS = {
  DISPLAY_APPLE_BUTTON: "display_apple_button",
  REMOVE_EMAIL_MORE_SOCIAL: "remove_email_more_social",
  SIDE_BY_SIDE_VS_TOTAL_PER_MONTH: "side_by_side_vs_total_per_month",
};

Object.freeze(FEATURE_FLAGS);
Object.freeze(OFFER_VARIANTS);

export { FEATURE_FLAGS, OFFER_VARIANTS };
