import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";

export default new Presenter(
  {
    currency: PropTypes.string,
    description: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    duration: PropTypes.number,
    durationUnit: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string.isRequired,
    name: PropTypes.string,
    price: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
  },
  {
    priceInCents(o) {
      return o.price * 100;
    },
    priceNumber(o) {
      return parseInt(this.price(o), 10);
    },
    priceRounded(o) {
      return Math.round(this.price(o));
    },
    displayOfferDuration(o) {
      return o?.durationUnit?.charAt(0).toUpperCase() + o?.durationUnit?.slice(1);
    },
    isHalfYearPlan(o) {
      return o?.duration === 6 && this.isMonthly(o);
    },
    isYearlyPlan(o) {
      return o?.durationUnit === "year";
    },
    isMonthly(o) {
      return o?.durationUnit === "month";
    },
    typeOfOffer(o) {
      return this.isYearlyPlan(o) ? "Yearly Plan" : `${o?.duration}-Month Plan`;
    },
    pricePerMonth(o) {
      return this.isYearlyPlan(o) ? this.priceNumber(o) / 12 : this.priceNumber(o) / o?.duration;
    },
    offerDescription(o) {
      if (this.isYearlyPlan(o)) {
        return `$${this.priceNumber(o)} billed annually`;
      }
      if (this.isHalfYearPlan(o)) {
        return `$${this.priceNumber(o)} billed semi-annually`;
      }

      return `$${this.priceNumber(o)} billed per ${o?.duration} Months`;
    },
    offerDescriptionPerMonth(o) {
      return `$${this.pricePerMonth(o)} per month`;
    },
    discount(o, pricePerYear) {
      const discountedPrice = this.priceNumber(o);
      const percentageSaved = ((pricePerYear - discountedPrice) / pricePerYear) * 100;
      const roundedPercentageSaved = Math.round(percentageSaved);

      return roundedPercentageSaved;
    },
  },
);
